import { ReactNode } from 'react';
import { useFieldArray, UseFieldArrayReturn } from 'react-hook-form';
import { useFormContext } from '../FormProvider';

export type ArrayFieldProps = {
  name: string;
  itemTemplate: (getName: (name: string) => string, remove: VoidFunction, index: number) => ReactNode;
  wrapperTemplate: (children: ReactNode, append: (value?: any) => void) => ReactNode;
  methodsRef?: React.MutableRefObject<UseFieldArrayReturn | null>;
};

function getNameFactory(name: string, index: number) {
  return function getName(fieldName: string) {
    return `${name}[${index}].${fieldName}`;
  };
}

export function ArrayField({ name, itemTemplate, wrapperTemplate, methodsRef }: ArrayFieldProps) {
  const { control } = useFormContext();
  const methods = useFieldArray({ name, control });
  if (methodsRef && !methodsRef.current) {
    methodsRef.current = methods;
  }
  const { fields, append, remove } = methods;

  const fieldsNode = fields.map((field, index) => (
    <div className='mb-6' key={field.id}>
      {itemTemplate(getNameFactory(name, index), () => remove(index), index)}
    </div>
  ));

  const wrapperNode = wrapperTemplate(fieldsNode, (value?: any) => append(value || {}));

  return <>{wrapperNode}</>;
}
