import { ButtonGroupField } from 'src/form';
import { DeliveryState } from 'src/types';

export function DeliveredField() {
  return (
    <ButtonGroupField
      name='deliveryState'
      label='Stare livrare'
      options={[
        { label: 'Livrat', value: DeliveryState.Delivered },
        { label: 'Nelivrat', value: DeliveryState.NotDelivered },
      ]}
    />
  );
}
