import { useState } from 'react';
import { Button } from './components';
import { FormContextProvider, TextField } from './form';
import { Router } from './Router';
import { authorize, isAuthorized } from './utils/authorize';
import axios from 'axios';

axios.defaults.baseURL = 'https://localhost:7113/';


function App() {
  const [authorized, setIsAuthorized] = useState(isAuthorized());

  return authorized ? (
    <Router />
  ) : (
    <FormContextProvider
      onSubmit={(data: { code: string }) => setIsAuthorized(authorize(data.code))}
      submitButtonTemplate={(isDisabled) => (
        <Button type='submit' disabled={isDisabled}>
          Verifica
        </Button>
      )}
    >
      <TextField label='Code' name='code' />
    </FormContextProvider>
  );
}

export default App;
