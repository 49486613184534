import { ButtonGroupField, FormContextProvider, TextField } from 'src/form';
import { useModal } from 'src/modal';
import { GetAllDocument, useGetOneQuery, useGetPrerequisitesQuery, useUpdateMutation } from './requests.generated';
import { ProductCategory, ProductProductionLine } from 'src/types';
import { RequirementsForm } from './RequirementsForm';

export function UpdateForm({ id }: any) {
  const { close } = useModal();

  const [updateMutation] = useUpdateMutation();
  const { data: entity, loading: entityLoading } = useGetOneQuery({ variables: { id } });
  const { data: prerequisites, loading: prerequisitesLoading } = useGetPrerequisitesQuery();

  return entityLoading || prerequisitesLoading ? null : (
    <FormContextProvider
      defaultValues={{ ...entity?.productDefinitions.one, productionLine: ProductProductionLine.First }}
      onSubmit={(request: any) => {
        delete request.productionLine;
        return updateMutation({ variables: { request }, onCompleted: close, refetchQueries: [GetAllDocument] })
      }
      }
    >
      <TextField name='name' label={'Nume'} />
      <TextField name='unit' label={'Unitate de masura'} />
      <ButtonGroupField
        name='category'
        label='Categorie'
        options={[
          { label: 'Adezivi', value: ProductCategory.Adezivi },
          { label: 'Vopsele', value: ProductCategory.Vopsele },
          { label: 'Grunduri', value: ProductCategory.Grunduri },
          { label: 'Altele', value: ProductCategory.Altele },
        ]}
      />
      <ButtonGroupField
        name='productionLine'
        label='Linia de producere'
        options={[
          { label: 'Linia 1', value: ProductProductionLine.First },
          { label: 'Linia 2', value: ProductProductionLine.Second },
        ]}
      />
      <RequirementsForm prerequisites={prerequisites!} />
    </FormContextProvider>
  );
}
