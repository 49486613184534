import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { FieldWrapper } from '../components';
import { useFormContext } from '../FormProvider';
import { Dropdown } from 'src/components';

export type SelectFieldProps = {
  name: string;
  label: string;
  hideLabel?: boolean;
  important?: boolean;
  options: { value: string; label?: string | null }[];
};

export function SelectField({ name, label, hideLabel, important, options }: SelectFieldProps) {
  const { control } = useFormContext();

  const getValue = (val: any) => options.find((x) => x.value === val);
  return (
    <FieldWrapper name={name} label={label} hideLabel={hideLabel}>
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          return (
            <Dropdown
              {...field}
              options={options}
              value={getValue(field.value)}
              defaultValue={getValue(field.value)}
              onChange={(val: any) => field.onChange(val?.value)}
              important={important}
            />
          );
        }}
      />
    </FieldWrapper>
  );
}
