import { forwardRef } from 'react';
import { FieldWrapper } from '../components';
import { useFormContext } from '../FormProvider';

export type HtmlInputFieldProps = React.InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  label: string;
  skipRegister?: boolean;
  hideLabel?: boolean;
  important?: boolean;
};

export const HtmlInputField = forwardRef(
  ({ label, skipRegister, name, hideLabel, important, ...rest }: HtmlInputFieldProps, ref) => {
    const { register } = useFormContext();
    return (
      <FieldWrapper name={name} label={label} hideLabel={hideLabel}>
        <input
          {...rest}
          {...(!skipRegister ? { ...register(name) } : ({ ref } as any))}
          name={name}
          className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 outline-none ${
            important ? 'bg-blue-100' : ''
          }`}
        />
      </FieldWrapper>
    );
  },
);
