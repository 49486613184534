import { ProductTransactionDetailsClassification } from 'src/types';
import { KpiCard } from './KpiCard';
import { GetAllQuery } from './requests.generated';
import { from } from 'linq-to-typescript';

type Props = {
  classifications: ProductTransactionDetailsClassification[];
  setClassifications: (
    setFunction: (current: ProductTransactionDetailsClassification[]) => ProductTransactionDetailsClassification[],
  ) => void;

  data?: GetAllQuery['productTransactions']['all'];
};

function toggle(
  setClassifications: Props['setClassifications'],
  set: Set<ProductTransactionDetailsClassification>,
  classification: ProductTransactionDetailsClassification,
) {
  return function () {
    setClassifications((classifications) => {
      if (set.has(classification)) {
        return classifications.filter((c) => c !== classification);
      }
      return [...classifications, classification];
    });
  };
}

function getProps(
  setClassifications: Props['setClassifications'],
  set: Set<ProductTransactionDetailsClassification>,
  classification: ProductTransactionDetailsClassification,
  data: GetAllQuery['productTransactions']['all'],
) {
  const current = from(data).where((x) => x.classification === classification);
  const entriesCount = current.count();
  const totalPrice = current.sum((x) => parseFloat(x.totalPrice));
  const totalTotalPrice = from(data).sum((x) => parseFloat(x.totalPrice));

  return {
    onClick: toggle(setClassifications, set, classification),
    active: set.has(classification),
    className: classification,
    entriesCount,
    totalPrice,
    percentage: (totalPrice / totalTotalPrice) * 100,
  };
}

export function Kpis({ classifications, setClassifications, data }: Props) {
  var set = new Set(classifications);

  const safeData = data || [];

  return (
    <div className='flex flex-wrap gap-6'>
      <KpiCard
        name='Rosu'
        {...getProps(setClassifications, set, ProductTransactionDetailsClassification.Red, safeData)}
      />
      <KpiCard
        name='Galben'
        {...getProps(setClassifications, set, ProductTransactionDetailsClassification.Yellow, safeData)}
      />
      <KpiCard
        name='Verde'
        {...getProps(setClassifications, set, ProductTransactionDetailsClassification.Green, safeData)}
      />
      <KpiCard
        name='Albastru'
        {...getProps(setClassifications, set, ProductTransactionDetailsClassification.Unknown, safeData)}
      />
      <KpiCard
        name='Toate'
        active={classifications.length === 4}
        onClick={() =>
          setClassifications(() => [
            ProductTransactionDetailsClassification.Green,
            ProductTransactionDetailsClassification.Red,
            ProductTransactionDetailsClassification.Yellow,
            ProductTransactionDetailsClassification.Unknown,
          ])
        }
        className={''}
        entriesCount={safeData.length}
        percentage={100}
        totalPrice={from(safeData).sum((x) => parseFloat(x.totalPrice))}
      />
      <KpiCard
        name='Nimic'
        active={classifications.length === 0}
        onClick={() => setClassifications(() => [])}
        className={''}
        percentage={0}
        entriesCount={0}
        totalPrice={0}
      />
    </div>
  );
}
