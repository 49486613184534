import { PropsWithChildren } from 'react';
import { Table, TableHeaders } from 'src/components';
import { GetAllQuery } from './requests.generated';

export interface RequirementsTableProps {
  requirements: GetAllQuery['productDefinitions']['all'][0]['requirements'];
}

export function RequirementsTable({ requirements }: PropsWithChildren<RequirementsTableProps>) {
  const headers: TableHeaders<RequirementsTableProps['requirements'][0]> = [
    { renderCell: (row) => row.name, title: 'Material' },
    { renderCell: (row) => row.quantity, title: 'Cantitate' },
  ];

  return <Table headers={headers} data={requirements} />;
}
