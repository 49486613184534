import { PropsWithChildren } from 'react';

export interface BoxProps {
  outline?: boolean;
  hidden?: boolean;
  onClose?: VoidFunction;
}

export function Box({ onClose, outline, hidden, children }: PropsWithChildren<BoxProps>) {
  return (
    <div
      className={`relative p-6 px-4 py-5 bg-white shadow-md rounded-sd min-w-72 rounded-md ${outline && 'border-blue-400 border-2'
        } ${hidden && 'hidden'}`}
    >
      {!!onClose && (
        <button
          type='button'
          onClick={onClose}
          className='absolute flex items-center justify-center w-6 h-6 text-white bg-gray-400 rounded-full shadow-md -top-2 -right-2 hover:bg-gray-500'
        >
          <svg
            aria-hidden='true'
            className='w-5 h-5'
            fill='currentColor'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
              clipRule='evenodd'
            ></path>
          </svg>
        </button>
      )}
      {children}
    </div>
  );
}
