import { ArrayField, NumericField, useFormContext } from 'src/form';
import { GetOneQuery, GetPrerequisitesQuery } from './requests.generated';
import { useEffect } from 'react';
import { Box, BoxContainer } from 'src/components';
import { getDeepPropertyValue } from 'src/utils';
import { ProductProductionLine } from 'src/types';

type Props = {
  prerequisites?: GetPrerequisitesQuery;
  defaultValues?: GetOneQuery;
};

function useUsagesUpdateOnProductChange({ prerequisites, defaultValues }: Props) {
  const { watch, setValue } = useFormContext();
  const productDefinitionId = watch('productDefinitionId');
  const productionLine = watch('productionLine') as ProductProductionLine;
  useEffect(() => {
    if (!prerequisites || !defaultValues) {
      return;
    }

    if (defaultValues?.productProductions?.one?.id === productDefinitionId && defaultValues?.productProductions?.one?.productionLine === productionLine) {
      setValue('usages', defaultValues?.productProductions?.one.usages);
    } else {
      const usages = prerequisites?.products?.all?.find((x) => x.value === productDefinitionId)?.requirements.filter(r => r.productionLine === productionLine).sort((x, y) => x.name.localeCompare(y.name));
      setValue('usages', usages);
    }
  }, [productDefinitionId, productionLine]);
}

function useUsagesQuantityChangeOnMixturesChange({ prerequisites, defaultValues }: Props) {
  const { watch, setValue } = useFormContext();

  const mixtures = watch('mixtures');
  const productDefinitionId = watch('productDefinitionId') as string;
  const productionLine = watch('productionLine') as ProductProductionLine;

  useEffect(() => {

    // Do not populate usages until the product and product line are selected
    if (!productDefinitionId || !productionLine) {
      return;
    }

    // When updating and the product was not changed yet and the mixtures was not populated, display old values saved in the database
    if (!mixtures && productDefinitionId === defaultValues?.productProductions?.one?.productDefinitionId) {
      setValue(
        'usages',
        defaultValues?.productProductions.one.usages.map((item) => ({
          quantity: item.quantity,
          materialDefinitionId: item.materialDefinitionId,
        })),
      );
      return;
    }

    // Compute new values based on requirements
    const usages = prerequisites?.products?.all?.find((x) => x.value === productDefinitionId)?.requirements?.sort((x, y) => x.name.localeCompare(y.name)) || [];

    const newValues = usages.filter(u => u.productionLine === productionLine).map((item) => ({
      quantity: (item.quantity * (mixtures === 0 ? 0 : mixtures || 1)).toFixed(2),
      materialDefinitionId: item.materialDefinitionId,
    }));

    setValue('usages', newValues);
  }, [productDefinitionId, productionLine, mixtures]);
}

export function UsagesForm(props: Props) {
  useUsagesUpdateOnProductChange(props);
  useUsagesQuantityChangeOnMixturesChange(props);

  const { watch } = useFormContext();

  const productDefinitionId = watch('productDefinitionId');
  const productionLine = watch('productionLine') as ProductProductionLine;

  const usages = props.prerequisites?.products.all.find((x) => x.value === productDefinitionId)?.requirements?.filter(r => r.productionLine === productionLine).sort((x, y) => x.name.localeCompare(y.name));
  console.log(usages)
  return (
    <ArrayField
      name='usages'
      itemTemplate={(getName) => (
        <Box>
          {getDeepPropertyValue({ usages }, getName('name') as any)}
          <NumericField name={getName('quantity')} label='Cantitate' />
        </Box>
      )}
      wrapperTemplate={(children) => <BoxContainer>{children}</BoxContainer>}
    />
  );
}
