import { DateField, FormContextProvider, NumericField, SelectField, TextField } from 'src/form';
import { useModal } from 'src/modal';
import { GetAllDocument, useGetOneQuery, useGetPrerequisitesQuery, useUpdateMutation } from './requests.generated';
import { UsagesForm } from './UsagesForm';
import { ProductionLineField } from './ProductionLineField';
import { WorkerField } from './WorkerField';

export function UpdateForm({ id }: any) {
  const { close } = useModal();

  const [updateMutation] = useUpdateMutation();
  const { data: entity, loading: entityLoading } = useGetOneQuery({ variables: { id } });
  const { data: prerequisites, loading: prerequisitesLoading } = useGetPrerequisitesQuery();

  return entityLoading || prerequisitesLoading ? null : (
    <FormContextProvider
      defaultValues={entity?.productProductions.one}
      onSubmit={(request: any) => {
        delete request.mixtures;
        return updateMutation({ variables: { request }, onCompleted: close, refetchQueries: [GetAllDocument] });
      }}
    >
      <SelectField label={'Nume'} name='productDefinitionId' options={prerequisites!.products.all} />
      <div className='flex gap-2'>
        <DateField name='createdAt' label={'Data creare'} />
        <NumericField label={'Cantitate'} name='quantity' />
        <NumericField label='Zamesuri' name='mixtures' />
      </div>
      <ProductionLineField />
      <WorkerField />
      <TextField name='comment' label={'Comentariu'} />
      <UsagesForm prerequisites={prerequisites} defaultValues={entity} />
    </FormContextProvider>
  );
}
