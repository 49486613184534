import { Controller } from 'react-hook-form';
import { ControlledDatePicker } from 'src/components';
import { FieldWrapper } from '../components';
import { useFormContext } from '../FormProvider';
import { DateOrDefault } from 'src/components/DatePicker/DateOrDefault';

export type DateFieldProps = {
  name: string;
  label: string;
  important?: boolean;
};

export function DateField({ label, name, important }: DateFieldProps) {
  const { control } = useFormContext();

  return (
    <FieldWrapper label={label} name={name}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <ControlledDatePicker
              date={new DateOrDefault(typeof value === 'string' ? new Date(value) : value)}
              onChange={(dateOrDefault) => onChange(dateOrDefault.date)}
              important={important}
            />
          );
        }}
      />
    </FieldWrapper>
  );
}
