import { Box, Table, TableHeaders } from 'src/components';
import { useModal } from 'src/modal';
import { UpdateForm } from './UpdateForm';
import { AddForm } from './AddForm';
import { RequirementsTable } from './RequirementsTable';
import { GetAllDocument, GetAllQuery, useDeleteByIdMutation, useGetAllQuery } from './requests.generated';
import { ProductProductionLine } from 'src/types';

const productionLineMapping = {
  [ProductProductionLine.None]: "Nedefinit",
  [ProductProductionLine.First]: "Linia 1",
  [ProductProductionLine.Second]: "Linia 2"
}

export function ProductDefinitionsPage() {
  const headers: TableHeaders<GetAllQuery['productDefinitions']['all'][0]> = [
    { renderCell: (row) => row.name, title: 'Nume' },
    { renderCell: (row) => row.unit, title: 'Unitate de masura' },
    { renderCell: (row) => row.category, title: 'Categoria' },
    {
      renderCell: (row) => <div className='flex'>
        <Box>
          <h1 className="text-xl text-center">Linia 1</h1>
          <RequirementsTable requirements={row.requirements.filter(r => r.productionLine === ProductProductionLine.First)} />
        </Box>
        <Box>
          <h1 className="text-xl text-center">Linia 2</h1>
          <RequirementsTable requirements={row.requirements.filter(r => r.productionLine === ProductProductionLine.Second)} />
        </Box>
      </div>,
      title: 'Materiale necesare',
    },
  ];


  const { show } = useModal();

  const { data } = useGetAllQuery();

  const [deleteMutation] = useDeleteByIdMutation();

  return (
    <div className='flex flex-col gap-3'>
      <div>
        <AddForm />
      </div>

      <Table
        data={data?.productDefinitions.all}
        headers={headers}
        onEdit={(item) => show(<UpdateForm id={item.id} />)}
        onDelete={(item) => deleteMutation({ variables: { id: item.id }, refetchQueries: [GetAllDocument] })}
      />
    </div>
  );
}
