import { useFormContext } from 'src/form';
import { FieldWrapper } from 'src/form/components';
import { AddProductTransactionItemRequestInput } from 'src/types';

export function TotalProductPrice({ getName, hideLabel }: { hideLabel: boolean; getName: (name: string) => string }) {
  const { watch } = useFormContext();

  const price = watch(getName('price')) as number;
  const quantity = watch(getName('quantity')) as number;

  const totalPrice = price * quantity;

  const totalString = isNaN(totalPrice) ? 'Incomplet' : `${totalPrice.toFixed(2)}`;

  return (
    <FieldWrapper label='Total' name='' hideLabel={hideLabel}>
      <input
        value={totalString}
        disabled
        className='bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 outline-none'
      />
    </FieldWrapper>
  );
}

export function TotalTransactionPrice() {
  const { watch } = useFormContext();

  const transactions = watch('transactions') as AddProductTransactionItemRequestInput[];

  const totalPrice = transactions?.reduce((total, transaction) => total + (transaction.price ?? 0) * (transaction.quantity ?? 0), 0).toFixed(2);

  const totalString = `${totalPrice} MDL`;

  return <div className='w-full font-bold'>Total: {totalString}</div>;
}
