import { PropsWithChildren } from 'react';
import { ErrorMessage } from './ErrorMessage';

export interface FieldWrapperProps {
  name: string;
  label: string;
  hideLabel?: boolean;
  important?: boolean;
}

export function FieldWrapper({ label, name, hideLabel, important, children }: PropsWithChildren<FieldWrapperProps>) {
  return (
    <div className='mb-6 w-full'>
      {!hideLabel && (
        <label
          htmlFor={name}
          className={`block mb-2 text-sm font-medium text-gray-700 ${important ? 'text-black text-lg' : ''}`}
        >
          {label}
        </label>
      )}
      {children}
      <ErrorMessage name={name} />
    </div>
  );
}
