import { Table, TableHeaders } from 'src/components';
import { useModal } from 'src/modal';
import { KnownName } from 'src/types';
import { AddForm } from './AddForm';
import { GetAllDocument, useDeleteByIdMutation, useGetAllQuery } from './requests.generated';
import { UpdateForm } from './UpdateForm';
import { mapTypeToString } from './utils';

const headers: TableHeaders<KnownName> = [{ title: 'Nume', renderCell: (row) => row.name }];

export function KnownNamesPage() {
  const { show } = useModal();
  const { data } = useGetAllQuery();
  const [deleteMutation] = useDeleteByIdMutation();

  const commonTableProps = {
    headers,
    onEdit: (item: KnownName) => show(<UpdateForm id={item.id} />),
    onDelete: (item: KnownName) => {
      deleteMutation({ variables: { id: item.id }, refetchQueries: [GetAllDocument] });
    },
  };

  const buyers = data?.knowNames.buyers ?? [];
  const sellers = data?.knowNames.sellers ?? [];
  const workers = data?.knowNames.workers ?? [];

  return (
    <div className='flex flex-col gap-3'>
      <div>
        <AddForm />
      </div>
      <div className='flex md:flex-row md:justify-around md:wrap'>
        <Table
          title={`Cumparatori (${buyers.length})`}
          data={buyers.map(
            (x) =>
            ({
              ...x,
              type: mapTypeToString(x.type),
            } as any),
          )}
          {...commonTableProps}
        />
        <Table
          title={`Vanzatori (${sellers.length})`}
          data={sellers.map(
            (x) =>
            ({
              ...x,
              type: mapTypeToString(x.type),
            } as any),
          )}
          {...commonTableProps}
        />
        <Table
          title={`Lucratori (${workers.length})`}
          data={workers.map(
            (x) =>
            ({
              ...x,
              type: mapTypeToString(x.type),
            } as any),
          )}
          {...commonTableProps}
        />
      </div>
    </div>
  );
}
