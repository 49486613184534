import { Button } from 'src/components';
import { DateField, FormContextProvider, NumericField, SelectField, TextField } from 'src/form';
import { useModal } from 'src/modal';
import { GetAllDocument, useAddMutation, useGetPrerequisitesQuery } from './requests.generated';
import { UsagesForm } from './UsagesForm';
import { ProductionLineField } from './ProductionLineField';
import { WorkerField } from './WorkerField';

export function AddForm() {
  const { show, close } = useModal();

  const [addMutation] = useAddMutation();
  const { data: prerequisites, loading: prerequisitesLoading } = useGetPrerequisitesQuery();

  return (
    <Button
      onClick={() =>
        show(
          prerequisitesLoading ? null : (
            <FormContextProvider
              onSubmit={(request: any) => {
                delete request.mixtures;
                return addMutation({ variables: { request }, onCompleted: close, refetchQueries: [GetAllDocument] });
              }}
              defaultValues={{ createdAt: new Date(), mixtures: 0, quantity: 0 }}
            >
              <SelectField label={'Nume'} name='productDefinitionId' options={prerequisites!.products.all} />
              <div className='flex gap-2'>
                <DateField name='createdAt' label={'Data creare'} />
                <NumericField label={'Cantitate'} name='quantity' />
                <NumericField label='Zamesuri' name='mixtures' />
              </div>
              <ProductionLineField />
              <WorkerField />
              <TextField name='comment' label={'Comentariu'} />
              <UsagesForm prerequisites={prerequisites} />
            </FormContextProvider>
          ),
        )
      }
    >
      Adauga
    </Button>
  );
}
