import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllQuery = { knowNames: { buyers: Array<{ id: any, name?: string | null, type: Types.KnownNameType }>, sellers: Array<{ id: any, name?: string | null, type: Types.KnownNameType }>, workers: Array<{ id: any, name?: string | null, type: Types.KnownNameType }> } };

export type GetOneQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type GetOneQuery = { knowNames: { one: { id: any, name?: string | null, type: Types.KnownNameType } } };

export type AddMutationVariables = Types.Exact<{
  request: Types.AddKnownNameRequestInput;
}>;


export type AddMutation = { knowNames: { add: any } };

export type UpdateMutationVariables = Types.Exact<{
  request: Types.UpdateKnownNameRequestInput;
}>;


export type UpdateMutation = { knowNames: { update: boolean } };

export type DeleteByIdMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type DeleteByIdMutation = { knowNames: { delete: boolean } };


export const GetAllDocument = gql`
    query getAll {
  knowNames {
    buyers: all(where: {type: {eq: BUYER}}, order: {name: ASC}) {
      id
      name
      type
    }
    sellers: all(where: {type: {eq: SELLER}}, order: {name: ASC}) {
      id
      name
      type
    }
    workers: all(where: {type: {eq: WORKER}}, order: {name: ASC}) {
      id
      name
      type
    }
  }
}
    `;

/**
 * __useGetAllQuery__
 *
 * To run a query within a React component, call `useGetAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllQuery(baseOptions?: Apollo.QueryHookOptions<GetAllQuery, GetAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllQuery, GetAllQueryVariables>(GetAllDocument, options);
      }
export function useGetAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllQuery, GetAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllQuery, GetAllQueryVariables>(GetAllDocument, options);
        }
export type GetAllQueryHookResult = ReturnType<typeof useGetAllQuery>;
export type GetAllLazyQueryHookResult = ReturnType<typeof useGetAllLazyQuery>;
export type GetAllQueryResult = Apollo.QueryResult<GetAllQuery, GetAllQueryVariables>;
export const GetOneDocument = gql`
    query getOne($id: UUID!) {
  knowNames {
    one(id: $id) {
      id
      name
      type
    }
  }
}
    `;

/**
 * __useGetOneQuery__
 *
 * To run a query within a React component, call `useGetOneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOneQuery(baseOptions: Apollo.QueryHookOptions<GetOneQuery, GetOneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOneQuery, GetOneQueryVariables>(GetOneDocument, options);
      }
export function useGetOneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOneQuery, GetOneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOneQuery, GetOneQueryVariables>(GetOneDocument, options);
        }
export type GetOneQueryHookResult = ReturnType<typeof useGetOneQuery>;
export type GetOneLazyQueryHookResult = ReturnType<typeof useGetOneLazyQuery>;
export type GetOneQueryResult = Apollo.QueryResult<GetOneQuery, GetOneQueryVariables>;
export const AddDocument = gql`
    mutation add($request: AddKnownNameRequestInput!) {
  knowNames {
    add(addRequest: $request)
  }
}
    `;
export type AddMutationFn = Apollo.MutationFunction<AddMutation, AddMutationVariables>;

/**
 * __useAddMutation__
 *
 * To run a mutation, you first call `useAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMutation, { data, loading, error }] = useAddMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useAddMutation(baseOptions?: Apollo.MutationHookOptions<AddMutation, AddMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMutation, AddMutationVariables>(AddDocument, options);
      }
export type AddMutationHookResult = ReturnType<typeof useAddMutation>;
export type AddMutationResult = Apollo.MutationResult<AddMutation>;
export type AddMutationOptions = Apollo.BaseMutationOptions<AddMutation, AddMutationVariables>;
export const UpdateDocument = gql`
    mutation update($request: UpdateKnownNameRequestInput!) {
  knowNames {
    update(updateRequest: $request)
  }
}
    `;
export type UpdateMutationFn = Apollo.MutationFunction<UpdateMutation, UpdateMutationVariables>;

/**
 * __useUpdateMutation__
 *
 * To run a mutation, you first call `useUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMutation, { data, loading, error }] = useUpdateMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMutation, UpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMutation, UpdateMutationVariables>(UpdateDocument, options);
      }
export type UpdateMutationHookResult = ReturnType<typeof useUpdateMutation>;
export type UpdateMutationResult = Apollo.MutationResult<UpdateMutation>;
export type UpdateMutationOptions = Apollo.BaseMutationOptions<UpdateMutation, UpdateMutationVariables>;
export const DeleteByIdDocument = gql`
    mutation deleteById($id: UUID!) {
  knowNames {
    delete(id: $id)
  }
}
    `;
export type DeleteByIdMutationFn = Apollo.MutationFunction<DeleteByIdMutation, DeleteByIdMutationVariables>;

/**
 * __useDeleteByIdMutation__
 *
 * To run a mutation, you first call `useDeleteByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteByIdMutation, { data, loading, error }] = useDeleteByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteByIdMutation, DeleteByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteByIdMutation, DeleteByIdMutationVariables>(DeleteByIdDocument, options);
      }
export type DeleteByIdMutationHookResult = ReturnType<typeof useDeleteByIdMutation>;
export type DeleteByIdMutationResult = Apollo.MutationResult<DeleteByIdMutation>;
export type DeleteByIdMutationOptions = Apollo.BaseMutationOptions<DeleteByIdMutation, DeleteByIdMutationVariables>;