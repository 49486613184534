import { ButtonGroupField, MultipleButtonGroupField } from "src/form";
import { ProductProductionLine } from "src/types";
import { useGetWorkersQuery } from "./requests.generated";

export function WorkerField() {

    const { data } = useGetWorkersQuery();

    return <MultipleButtonGroupField
        name='workerIds'
        label='Linia de producere'
        options={data?.knowNames?.all as any || []}
    />;
}