import { Button } from 'src/components';
import { FormContextProvider, TextField } from 'src/form';
import { useModal } from 'src/modal';
import { GetAllDocument, useAddMutation } from './requests.generated';

export function AddForm() {
  const { show, close } = useModal();

  const [addMutation] = useAddMutation();

  return (
    <Button
      onClick={() =>
        show(
          <FormContextProvider
            onSubmit={(request: any) =>
              addMutation({ variables: { request }, onCompleted: close, refetchQueries: [GetAllDocument] })
            }
          >
            <TextField name='name' label={'Nume'} />
            <TextField name='unit' label={'Unitate de masura'} />
          </FormContextProvider>,
        )
      }
    >
      Adauga
    </Button>
  );
}
