import { TableHeader } from './TableHeader';
import { TableRow } from './TableRow';

export interface TableProps<T> {
  title?: string;
  headers: TableHeader<T>[];
  data?: T[];
  onEdit?: (item: T) => void;
  onDelete?: (item: T) => void;
}

const thClassNames = 'px-6 py-3';
export const tdClassNames = 'px-6 py-4';

export function Table<T>({ title, headers, data, onEdit, onDelete }: ComponentArgs<TableProps<T>>) {
  const hasEdit = Boolean(onEdit);
  const hasDelete = Boolean(onDelete);

  const hasActions = hasEdit || hasDelete;

  return (
    <div className='relative overflow-x-auto shadow-md sm:rounded-lg'>
      {title && <h1 className='text-2xl mb-2 p-2 bg-gray-50'>{title}</h1>}
      <table className='w-full text-sm font-medium text-left text-black'>
        <thead className='text-xs text-black uppercase bg-gray-50'>
          <tr>
            {headers.map((header, headerIndex) => (
              <th className={thClassNames} key={headerIndex}>
                {header.title}
              </th>
            ))}
            {hasActions && <th className={thClassNames}></th>}
          </tr>
        </thead>
        <tbody>
          {data?.map((item, idx) => (
            <TableRow key={idx} index={idx} {...{ onEdit, onDelete, item, headers, hasActions, hasEdit, hasDelete }} />
            // @ts-ignore
          ))}
        </tbody>
      </table>
    </div>
  );
}
