import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { FC, MouseEventHandler, PropsWithChildren } from 'react';
import { combineClassNames } from 'src/utils';
import { Dropdown } from '../Dropdown';
import { formatDate, getYearsOptions } from './DateHelpers';

export interface DatePickerHeaderProps {
  monthDate: Date;
  date: Date;
  changeYear(year: number): void;
  changeMonth(month: number): void;
  customHeaderCount: number;
  decreaseMonth(): void;
  increaseMonth(): void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
  decreaseYear(): void;
  increaseYear(): void;
  prevYearButtonDisabled: boolean;
  nextYearButtonDisabled: boolean;
}

const classNames = {
  button:
    'inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500',
  buttonDisabled: 'cursor-not-allowed opacity-50',
  icon: 'w-5 h-5 text-gray-600',
};

function getButtonClassNames(isDisabled: boolean) {
  const names = [classNames.button];
  if (isDisabled) {
    names.unshift(classNames.buttonDisabled);
  }
  return combineClassNames(...names);
}

function ChangeMonthButton({
  isDisabled,
  onClick,
  Icon,
}: {
  isDisabled: boolean;
  onClick: MouseEventHandler;
  Icon: FC<{ className: string }>;
}) {
  return (
    <button onClick={onClick} disabled={isDisabled} type='button' className={getButtonClassNames(isDisabled)}>
      <Icon className={classNames.icon} />
    </button>
  );
}

function ChangeYearButton({ date, changeYear }: { date: Date; changeYear: (year: number) => void }) {
  const options = getYearsOptions();
  const getValue = (val: any) => options.find((x) => x.value === val);

  return (
    <Dropdown
      value={getValue(date.getFullYear().toString())}
      defaultValue={getValue(date.getFullYear().toString())}
      isClearable={false}
      options={options}
      onChange={(year: { label: string; value: string | undefined }) => {
        changeYear((year.value && parseInt(year.value)) || date.getFullYear());
      }}
    />
  );
}

export function DatePickerHeader({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  changeYear,
}: PropsWithChildren<DatePickerHeaderProps>) {
  return (
    <>
      <div className='flex items-center justify-between px-2 py-2'>
        <span className='text-lg text-gray-700'>{formatDate(date)}</span>

        <div className='space-x-2'>
          <ChangeMonthButton Icon={ChevronLeftIcon} isDisabled={prevMonthButtonDisabled} onClick={decreaseMonth} />
          <ChangeMonthButton Icon={ChevronRightIcon} isDisabled={nextMonthButtonDisabled} onClick={increaseMonth} />
        </div>
      </div>
      <div>
        <ChangeYearButton date={date} changeYear={changeYear} />
      </div>
    </>
  );
}
