import { DownloadIcon } from '@heroicons/react/outline';
import axios from 'axios';
import { useRef } from 'react';
import { useMutation } from 'react-query';
import { IconButton } from 'src/components';
import { FileField, FormContextProvider } from 'src/form';

export function SettingsPage() {
  const formRef = useRef(null);
  const mutation = useMutation((args: { request: any }) =>
    axios
      .post(`/api/snapshot`, args.request.archive, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => res.data),
  );
  return (
    <div>
      <div>V 0.0.4</div>
      <hr />
      <div className='flex m-4'>
        <IconButton href='/api/snapshot' Icon={DownloadIcon} />
        <FormContextProvider onSubmit={(f) => mutation.mutate({ request: f })}>
          <FileField name='archive' label='Archive' />
        </FormContextProvider>
      </div>
    </div>
  );
}
