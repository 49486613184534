import { DateRangePicker, Table, TableHeaders } from 'src/components';
import { useModal } from 'src/modal';
import { UpdateForm } from './UpdateForm';
import { AddForm } from './AddForm';
import { GetAllDocument, GetAllQuery, useDeleteByIdMutation, useGetAllQuery } from './requests.generated';
import { DateRangeOrDefault } from 'src/components/DatePicker/DateRangeOrDefault';
import { useDateRange } from 'src/hooks/useDateRange';

export function MaterialTransactionsPage() {
  const headers: TableHeaders<GetAllQuery['materialTransactions']['all'][0]> = [
    { renderCell: (row) => row.receivedAt, title: 'Data primire' },
    { renderCell: (row) => row.receivedFrom, title: 'Nume furnizor' },
    { renderCell: (row) => row.name, title: 'Nume material' },
    { renderCell: (row) => row.quantity, title: 'Cantitate' },
  ];

  const { show } = useModal();
  const [variables, interval, setRange] = useDateRange(DateRangeOrDefault.Default);

  const [deleteMutation] = useDeleteByIdMutation();

  const { data } = useGetAllQuery({ variables });
  return (
    <div className='flex flex-col gap-3'>
      <div>
        <AddForm />
      </div>
      <DateRangePicker label={'Interval'} onChange={setRange} interval={interval} />
      <Table
        data={data?.materialTransactions.all}
        headers={headers}
        onEdit={(item) => show(<UpdateForm id={item.id} />)}
        onDelete={(item) => deleteMutation({ variables: { id: item.id }, refetchQueries: [GetAllDocument] })}
      />
    </div>
  );
}
