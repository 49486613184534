import { Button } from 'src/components';
import {
  ArrayField,
  ButtonGroupField,
  DateField,
  FormContextProvider,
  NumericField,
  SelectField,
  TextField,
} from 'src/form';

const options = [
  { value: '1', label: 'First' },
  { value: '2', label: 'Second' },
];

export function FormInputs() {
  return (
    <FormContextProvider onSubmit={(submitData) => console.log(submitData)}>
      <TextField name='text' label='Text field' />
      <NumericField name='numeric' label='Numeric field' />
      <SelectField name='select' label='Select field' options={options} />
      <DateField name='date' label='Date field' />
      <ButtonGroupField
        name='btn'
        label='Button group'
        options={[
          { label: 'First', value: 1 },
          { label: 'Second', value: 2 },
          { label: 'Third', value: 3 },
        ]}
      />
      <ArrayField
        name='arr'
        itemTemplate={(getName, remove) => (
          <div>
            <TextField label='T1' name={getName('t1')} />
            <NumericField label='T2' name={getName('t2')} />
            <Button onClick={remove}>Remove</Button>
          </div>
        )}
        wrapperTemplate={(children, append) => (
          <div>
            {children}
            <Button onClick={() => append()}>Add</Button>
          </div>
        )}
      />
    </FormContextProvider>
  );
}
