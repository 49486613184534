import { PropsWithChildren, useCallback } from 'react';
import { ControlledDatePicker } from './ControlledDatePicker';
import { DateRangeOrDefault } from './DateRangeOrDefault';
import { DateOrDefault } from './DateOrDefault';

export interface ControlledDateRangePickerProps {
  label?: string;
  interval: DateRangeOrDefault;
  onChange: (interval: DateRangeOrDefault) => void;
}

export function ControlledDateRangePicker({
  label,
  interval,
  onChange,
}: PropsWithChildren<ControlledDateRangePickerProps>) {
  const onStartChange = useCallback((start: DateOrDefault) => onChange(interval.replaceStart(start)), [interval]);
  const onEndChange = useCallback((end: DateOrDefault) => onChange(interval.replaceEnd(end)), [interval]);
  return (
    <div className='flex items-center gap-2'>
      {label && <span className='font-medium text-gray-900'>{label}</span>}
      <ControlledDatePicker
        date={interval.start}
        startDate={interval.start}
        endDate={interval.end}
        onChange={onStartChange}
      />

      <ControlledDatePicker
        date={interval.end}
        startDate={interval.start}
        endDate={interval.end}
        onChange={onEndChange}
      />
    </div>
  );
}
