import { useState } from 'react';
import { GetLogsQuery } from './requests.generated';
import ReactDiffViewer from 'react-diff-viewer-continued';
import { formatDateTime } from 'src/components/DatePicker/DateHelpers';

type Props = React.PropsWithChildren & {
  log: GetLogsQuery['changeLogEntries'][0];
};

//

function getOperationName(log: Props['log']) {
  if (!log.newJson) {
    return 'Sters';
  }

  if (!log.oldJson) {
    return 'Adaugat';
  }

  return 'Actualizat';
}

const emptyJson = '{}';

export function LogEntry({ log }: Props) {
  const [expanded, setExpanded] = useState(false);

  const toggle = () => setExpanded((x) => !x);
  return (
    <>
      <div className={`p-4 cursor-pointer ${expanded ? 'bg-blue-500 text-white' : ''}`} onClick={toggle}>
        {getOperationName(log)}
      </div>
      <div className={`p-4 cursor-pointer ${expanded ? 'bg-blue-500 text-white' : ''}`} onClick={toggle}>
        {log.entityName}
      </div>
      <div className={`p-4 cursor-pointer ${expanded ? 'bg-blue-500 text-white' : ''}`} onClick={toggle}>
        {formatDateTime(log.timestamp)}
      </div>
      {!expanded ? null : (
        <div className='col-span-full'>
          <ReactDiffViewer
            oldValue={log.oldJson || emptyJson}
            newValue={log.newJson || emptyJson}
            splitView={true}
            showDiffOnly={false}
          />
        </div>
      )}
    </>
  );
}
