import { PropsWithChildren, useRef, useState } from 'react';
import Popup from 'reactjs-popup';
import { PopupActions } from 'reactjs-popup/dist/types';
import { Box } from './Box';
import { Button } from './Button';
import { Text } from './Text';

export interface ConfirmationPopupProps {
  title: string;
  message: string;
  onConfirm?: VoidFunction;
  onCancel?: VoidFunction;
}

export function ConfirmationPopup({
  title,
  message,
  onConfirm,
  onCancel,
  children,
}: PropsWithChildren<ConfirmationPopupProps>) {
  const ref = useRef<PopupActions>(null);

  const closeTooltip = () => ref.current?.close();

  const [isOpen, setOpen] = useState(false);
  return (
    <Popup
      ref={ref}
      open={isOpen}
      trigger={children as any}
      keepTooltipInside
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <Box outline>
        <Text variant='title'>{title}</Text>
        <hr />
        <div className='my-4'>
          <Text variant='normal'>{message}</Text>
        </div>
        <div className='flex flex-row-reverse gap-2'>
          <Button
            onClick={() => {
              onCancel && onCancel();
              closeTooltip();
            }}
          >
            Anuleaza
          </Button>
          <Button
            onClick={() => {
              onConfirm && onConfirm();
              closeTooltip();
            }}
          >
            Confirma
          </Button>
        </div>
      </Box>
    </Popup>
  );
}
