import { PropsWithChildren } from 'react';

export interface TextProps {
  variant?: 'normal' | 'title' | 'subtitle';
}

export function Text({ variant, children }: PropsWithChildren<TextProps>) {
  switch (variant) {
    case 'title':
      return <h3 className='mb-4 text-xl font-medium text-gray-900'>{children}</h3>;
    case 'subtitle':
      return <h3 className='mb-4 text-l font-medium text-gray-900'>{children}</h3>;
    case 'normal':
    default:
      return <span>{children}</span>;
  }
}
