import { Button } from 'src/components';
import { ButtonGroupField, FormContextProvider, TextField } from 'src/form';
import { useModal } from 'src/modal';
import { KnownNameType } from 'src/types';
import { GetAllDocument, useAddMutation } from './requests.generated';

export function AddForm() {
  const { show, close } = useModal();
  const [addMutation] = useAddMutation();

  const form = (
    <FormContextProvider
      onSubmit={(request) =>
        addMutation({ variables: { request }, onCompleted: close, refetchQueries: [GetAllDocument] })
      }
    >
      <TextField name='name' label='Nume' />
      <ButtonGroupField
        name='type'
        label='Tip'
        options={[
          { label: 'Vanzator', value: KnownNameType.Seller },
          { label: 'Cumparator', value: KnownNameType.Buyer },
          { label: 'Lucrator', value: KnownNameType.Worker },
        ]}
      />
    </FormContextProvider>
  );

  return <Button onClick={() => show(form)}>Adauga</Button>;
}
