import { Navigate, Route, Routes } from 'react-router-dom';
import { NavBar } from './layout/NavBar';
import { KnownNamesPage } from './pages/knownNames';
import { MaterialDefinitionsPage } from './pages/materialDefinitions';
import { MaterialStockPage } from './pages/materialStock';
import { MaterialTransactionsPage } from './pages/materialTransactions';
import { ProductDefinitionsPage } from './pages/productDefinitions';
import { ProductProductionPage } from './pages/productProduction';
import { ProductStockPage } from './pages/productStock';
import { ProductTransactionsPage } from './pages/productTransactions';
import { SettingsPage } from './pages/settings';

import { FormInputs, Modals } from './pages/testing';
import { LogsPage } from './pages/logs';
import { WorkersPage } from './pages/workers';

export function Router() {
  return (
    <NavBar>
      <Routes>
        <Route index element={<Navigate replace to='/product/transactions' />} />
        <Route path='testing'>
          <Route path='inputs' element={<FormInputs />} />
          <Route path='modals' element={<Modals />} />
        </Route>
        <Route path='knownNames' element={<KnownNamesPage />} />
        <Route path='material'>
          <Route path='definitions' element={<MaterialDefinitionsPage />} />
          <Route path='transactions' element={<MaterialTransactionsPage />} />
          <Route path='stock' element={<MaterialStockPage />} />
        </Route>
        <Route path='product'>
          <Route path='definitions'>
            <Route index element={<ProductDefinitionsPage />} />
          </Route>
          <Route path='stock' element={<ProductStockPage />} />
          <Route path='production'>
            <Route index element={<ProductProductionPage />} />
          </Route>
          <Route path='transactions'>
            <Route index element={<ProductTransactionsPage />} />
          </Route>
        </Route>
        <Route path='logs'>
          <Route index element={<LogsPage />} />
        </Route>
        <Route path='workers'>
          <Route index element={<WorkersPage />} />
        </Route>
        <Route path='settings' element={<SettingsPage />} />
        <Route path='*' element={'Not found'} />
      </Routes>
    </NavBar>
  );
}
