import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllQueryVariables = Types.Exact<{
  start?: Types.InputMaybe<Types.Scalars['DateTime']>;
  end?: Types.InputMaybe<Types.Scalars['DateTime']>;
  classifications?: Types.InputMaybe<Array<Types.ProductTransactionDetailsClassification> | Types.ProductTransactionDetailsClassification>;
  sellerIds: Array<Types.Scalars['UUID']> | Types.Scalars['UUID'];
}>;


export type GetAllQuery = { productTransactions: { all: Array<{ id: any, comment: string, soldTo: string, soldAt: string, totalPrice: string, classification: Types.ProductTransactionDetailsClassification, deliveryState: Types.DeliveryState }> } };

export type GetPrerequisitesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPrerequisitesQuery = { buyers: { all: Array<{ value: any, label?: string | null }> }, products: { all: Array<{ value: any, label: string }> } };

export type GetOneQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type GetOneQuery = { productTransactions: { one: { id: any, comment: string, soldToId: any, classification: Types.ProductTransactionDetailsClassification, deliveryState: Types.DeliveryState, soldAt: any, transactions: Array<{ price: number, quantity: number, productDefinitionId: any }> } } };

export type AddMutationVariables = Types.Exact<{
  request: Types.AddProductTransactionRequestInput;
}>;


export type AddMutation = { productTransactions: { add: any } };

export type UpdateMutationVariables = Types.Exact<{
  request: Types.UpdateProductTransactionRequestInput;
}>;


export type UpdateMutation = { productTransactions: { update: boolean } };

export type DeleteByIdMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type DeleteByIdMutation = { productTransactions: { delete: boolean } };


export const GetAllDocument = gql`
    query getAll($start: DateTime, $end: DateTime, $classifications: [ProductTransactionDetailsClassification!], $sellerIds: [UUID!]!) {
  productTransactions {
    all(
      where: {soldAtDateTime: {gte: $start, lte: $end}, classification: {in: $classifications}, soldToId: {in: $sellerIds}}
      order: {soldAtDateTime: DESC, soldTo: ASC}
    ) {
      id
      comment
      soldTo
      soldAt
      totalPrice
      classification
      deliveryState
    }
  }
}
    `;

/**
 * __useGetAllQuery__
 *
 * To run a query within a React component, call `useGetAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      classifications: // value for 'classifications'
 *      sellerIds: // value for 'sellerIds'
 *   },
 * });
 */
export function useGetAllQuery(baseOptions: Apollo.QueryHookOptions<GetAllQuery, GetAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllQuery, GetAllQueryVariables>(GetAllDocument, options);
      }
export function useGetAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllQuery, GetAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllQuery, GetAllQueryVariables>(GetAllDocument, options);
        }
export type GetAllQueryHookResult = ReturnType<typeof useGetAllQuery>;
export type GetAllLazyQueryHookResult = ReturnType<typeof useGetAllLazyQuery>;
export type GetAllQueryResult = Apollo.QueryResult<GetAllQuery, GetAllQueryVariables>;
export const GetPrerequisitesDocument = gql`
    query getPrerequisites {
  buyers: knowNames {
    all(where: {type: {eq: BUYER}}, order: {name: ASC}) {
      value: id
      label: name
    }
  }
  products: productDefinitions {
    all(order: {name: ASC}) {
      value: id
      label: name
    }
  }
}
    `;

/**
 * __useGetPrerequisitesQuery__
 *
 * To run a query within a React component, call `useGetPrerequisitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrerequisitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrerequisitesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPrerequisitesQuery(baseOptions?: Apollo.QueryHookOptions<GetPrerequisitesQuery, GetPrerequisitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrerequisitesQuery, GetPrerequisitesQueryVariables>(GetPrerequisitesDocument, options);
      }
export function useGetPrerequisitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrerequisitesQuery, GetPrerequisitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrerequisitesQuery, GetPrerequisitesQueryVariables>(GetPrerequisitesDocument, options);
        }
export type GetPrerequisitesQueryHookResult = ReturnType<typeof useGetPrerequisitesQuery>;
export type GetPrerequisitesLazyQueryHookResult = ReturnType<typeof useGetPrerequisitesLazyQuery>;
export type GetPrerequisitesQueryResult = Apollo.QueryResult<GetPrerequisitesQuery, GetPrerequisitesQueryVariables>;
export const GetOneDocument = gql`
    query getOne($id: UUID!) {
  productTransactions {
    one(id: $id) {
      id
      comment
      soldToId
      soldAt: soldAtDateTime
      classification
      deliveryState
      transactions {
        price
        quantity
        productDefinitionId
      }
    }
  }
}
    `;

/**
 * __useGetOneQuery__
 *
 * To run a query within a React component, call `useGetOneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOneQuery(baseOptions: Apollo.QueryHookOptions<GetOneQuery, GetOneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOneQuery, GetOneQueryVariables>(GetOneDocument, options);
      }
export function useGetOneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOneQuery, GetOneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOneQuery, GetOneQueryVariables>(GetOneDocument, options);
        }
export type GetOneQueryHookResult = ReturnType<typeof useGetOneQuery>;
export type GetOneLazyQueryHookResult = ReturnType<typeof useGetOneLazyQuery>;
export type GetOneQueryResult = Apollo.QueryResult<GetOneQuery, GetOneQueryVariables>;
export const AddDocument = gql`
    mutation add($request: AddProductTransactionRequestInput!) {
  productTransactions {
    add(addRequest: $request)
  }
}
    `;
export type AddMutationFn = Apollo.MutationFunction<AddMutation, AddMutationVariables>;

/**
 * __useAddMutation__
 *
 * To run a mutation, you first call `useAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMutation, { data, loading, error }] = useAddMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useAddMutation(baseOptions?: Apollo.MutationHookOptions<AddMutation, AddMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMutation, AddMutationVariables>(AddDocument, options);
      }
export type AddMutationHookResult = ReturnType<typeof useAddMutation>;
export type AddMutationResult = Apollo.MutationResult<AddMutation>;
export type AddMutationOptions = Apollo.BaseMutationOptions<AddMutation, AddMutationVariables>;
export const UpdateDocument = gql`
    mutation update($request: UpdateProductTransactionRequestInput!) {
  productTransactions {
    update(updateRequest: $request)
  }
}
    `;
export type UpdateMutationFn = Apollo.MutationFunction<UpdateMutation, UpdateMutationVariables>;

/**
 * __useUpdateMutation__
 *
 * To run a mutation, you first call `useUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMutation, { data, loading, error }] = useUpdateMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMutation, UpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMutation, UpdateMutationVariables>(UpdateDocument, options);
      }
export type UpdateMutationHookResult = ReturnType<typeof useUpdateMutation>;
export type UpdateMutationResult = Apollo.MutationResult<UpdateMutation>;
export type UpdateMutationOptions = Apollo.BaseMutationOptions<UpdateMutation, UpdateMutationVariables>;
export const DeleteByIdDocument = gql`
    mutation deleteById($id: UUID!) {
  productTransactions {
    delete(id: $id)
  }
}
    `;
export type DeleteByIdMutationFn = Apollo.MutationFunction<DeleteByIdMutation, DeleteByIdMutationVariables>;

/**
 * __useDeleteByIdMutation__
 *
 * To run a mutation, you first call `useDeleteByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteByIdMutation, { data, loading, error }] = useDeleteByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteByIdMutation, DeleteByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteByIdMutation, DeleteByIdMutationVariables>(DeleteByIdDocument, options);
      }
export type DeleteByIdMutationHookResult = ReturnType<typeof useDeleteByIdMutation>;
export type DeleteByIdMutationResult = Apollo.MutationResult<DeleteByIdMutation>;
export type DeleteByIdMutationOptions = Apollo.BaseMutationOptions<DeleteByIdMutation, DeleteByIdMutationVariables>;