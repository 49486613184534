import { HtmlInputField } from './HtmlInputField';

export type TextFieldProps = React.InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  label: string;
  important?: boolean;
};

export function TextField(props: TextFieldProps) {
  return <HtmlInputField {...props} type='text' />;
}
