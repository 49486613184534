import {
  CheckIcon,
  DocumentDownloadIcon,
  EyeIcon,
  PencilIcon,
  TrashIcon,
  XIcon,
  ClipboardCopyIcon,
} from '@heroicons/react/outline';
import { PropsWithChildren } from 'react';
import { IconButton } from 'src/components';
import { ConfirmationIconButton } from 'src/components/ConfirmationButton';
import { useDelayedRender } from 'src/hooks/useDelayedRender';
import './index.css';
import { GetAllDocument, GetAllQuery, useDeleteByIdMutation } from './requests.generated';
import { useModal } from 'src/modal';
import { UpdateForm } from './UpdateForm';
import { DeliveryState } from 'src/types';
import { CopyForm } from './CopyForm';

export interface TransactionBoxProps {
  transaction: GetAllQuery['productTransactions']['all'][0];
  index: number;
}

export function TransactionBox({
  transaction: { soldTo, comment, classification, totalPrice, id, deliveryState },
  index,
}: PropsWithChildren<TransactionBoxProps>) {
  const [deleteMutation] = useDeleteByIdMutation();
  const { show, close } = useModal();

  const shouldRender = useDelayedRender(index);
  return !shouldRender ? null : (
    <div className='relative transaction-box'>
      <div className={`flex gap-2 common ${classification}`}>
        <div className='flex-1'>
          <div className='title'>{soldTo}</div>
          <div>{totalPrice} MDL</div>
        </div>
        <div className='bg-white h-fit'>
          {deliveryState === DeliveryState.Delivered && (
            <CheckIcon className='w-8 h-8 text-green-500 border-2 border-green-500' />
          )}
          {deliveryState === DeliveryState.NotDelivered && (
            <XIcon className='w-8 h-8 text-red-500 border-2 border-red-500' />
          )}
        </div>
      </div>
      <div className='comment'>{comment}</div>
      <div className='actions-bar'>
        <IconButton
          Icon={EyeIcon}
          color='green'
          href={`/api/productTransactionDetails/invoice/${id}`}
          target='_blank'
        />
        <IconButton
          Icon={DocumentDownloadIcon}
          color='green'
          href={`/api/productTransactionDetails/invoice/${id}/attachment`}
        />
        <IconButton Icon={PencilIcon} color='blue' onClick={() => show(<UpdateForm id={id} onUpdate={close} />)} />
        <IconButton Icon={ClipboardCopyIcon} color='blue' onClick={() => show(<CopyForm id={id} onAdd={close} />)} />
        <ConfirmationIconButton
          buttonProps={{
            Icon: TrashIcon,
            color: 'red',
            onClick: () => deleteMutation({ variables: { id }, refetchQueries: [GetAllDocument] }),
          }}
          confirmationPopupProps={{
            title: 'Sigur vrei sa stergi asta?',
            message: 'Daca stergi, tot ce foloseste asta se va sterge!',
          }}
        />
      </div>
    </div>
  );
}
