import { ButtonGroupField } from "src/form";
import { ProductProductionLine } from "src/types";


export function ProductionLineField() {
    return <ButtonGroupField
        name='productionLine'
        label='Linia de producere'
        options={[
            { label: 'Linia 1', value: ProductProductionLine.First },
            { label: 'Linia 2', value: ProductProductionLine.Second },
        ]}
    />;
}
