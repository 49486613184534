import { useMemo, useState } from 'react';
import { DateRangeOrDefault } from 'src/components/DatePicker/DateRangeOrDefault';

export function useDateRange(defaultValue: DateRangeOrDefault): [
  {
    start: Date;
    end: Date;
  },
  DateRangeOrDefault,
  React.Dispatch<React.SetStateAction<DateRangeOrDefault>>,
] {
  const [range, setRange] = useState(defaultValue);
  const rangeValue = useMemo(() => range.get(), [range]);
  return [rangeValue, range, setRange];
}
