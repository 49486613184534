import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'red' | 'blue' | 'green';
  navigateTo?: string;
}

const baseClasses =
  'inline-block m-1 px-4 py-2 border-2 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-100 ease-in-out';

const redClasses = `${baseClasses} border-red-600 text-red-600`;
const blueClasses = `${baseClasses} border-blue-600 text-blue-600`;
const greenClasses = `${baseClasses} border-green-600 text-green-600`;
const grayClasses = `${baseClasses} border-gray-600 text-gray-600 cursor-not-allowed`;

function getClassName(variant: ButtonProps['variant'], disabled?: boolean) {
  if (disabled) return grayClasses;

  switch (variant) {
    case 'red':
      return redClasses;
    case 'green':
      return greenClasses;

    default:
      return blueClasses;
  }
}

export function Button({ variant, navigateTo, children, onClick, disabled, ...rest }: PropsWithChildren<ButtonProps>) {
  const navigate = useNavigate();
  const handleOnClick = navigateTo
    ? (e: any) => {
        onClick && onClick(e);
        navigate(navigateTo);
      }
    : onClick;
  return (
    <button type='button' {...rest} onClick={handleOnClick} className={getClassName(variant, disabled)}>
      {children}
    </button>
  );
}
