import Select from 'react-select';

export function Dropdown({ important, ...rest }: any) {
  return (
    <Select
      isClearable
      classNamePrefix='form-select'
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          neutral0: important ? 'rgb(219 234 254 / var(--tw-bg-opacity))' : 'rgb(249 250 251 / var(--tw-bg-opacity))',
          neutral15: 'red',
        },
      })}
      {...rest}
    />
  );
}
