import { useEffect, useState } from 'react';

/**
 * Will render the first 6 items them every remaining item
 * @param index
 * @returns
 */
export function useDelayedRender(index: number) {
  const [shouldRender, setShouldRender] = useState(index < 6);
  useEffect(() => {
    const id = setTimeout(() => {
      if (!shouldRender) {
        setShouldRender(true);
      }
    }, index * 4);
    return () => clearTimeout(id);
  }, []);

  return shouldRender;
}
