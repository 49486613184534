import { HtmlInputField } from './HtmlInputField';

export type FileFieldProps = React.InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  label: string;
};

export function FileField(props: FileFieldProps) {
  return <HtmlInputField {...props} type='file'/>;
}
