import { Button } from 'src/components';
import { DateField, FormContextProvider, NumericField, SelectField, TextField } from 'src/form';
import { useModal } from 'src/modal';
import { GetAllDocument, useAddMutation, useGetPrerequisitesQuery } from './requests.generated';

export function AddForm() {
  const { show, close } = useModal();
  const [addMutation] = useAddMutation();
  const { data: prerequisites, loading } = useGetPrerequisitesQuery();
  return (
    <Button
      onClick={() =>
        show(
          loading ? null : (
            <FormContextProvider
              onSubmit={(request: any) =>
                addMutation({ variables: { request }, onCompleted: close, refetchQueries: [GetAllDocument] })
              }
              // defaultValues={{ receivedAt: new Date() }}
            >
              <DateField name='receivedAt' label={'Data primire'} />
              <SelectField name='receivedFromId' label={'Nume furnizor'} options={prerequisites!.sellers.all} />
              <SelectField name='materialDefinitionId' label={'Nume material'} options={prerequisites!.materials.all} />
              <NumericField name='quantity' label={'Cantitate'} />
            </FormContextProvider>
          ),
        )
      }
    >
      Adauga
    </Button>
  );
}
