import { useEffect, useState } from 'react';

type ErrorHandlerListener = (errors: string[]) => void;

class ErrorHandler {
  private listener: ErrorHandlerListener;
  private static emptyListener: ErrorHandlerListener = () => {};

  constructor() {
    this.listener = ErrorHandler.emptyListener;
  }

  public listen(listener: ErrorHandlerListener) {
    this.listener = listener;
  }

  public forget() {
    this.listener = ErrorHandler.emptyListener;
  }

  public notify(errors: string[]) {
    this.listener(errors);
  }
}

export const globalErrorHandler = new ErrorHandler();

export function useErrorHandling(): [string[], () => void] {
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    globalErrorHandler.listen((newErrors) => setErrors((prevErrors) => [...prevErrors, ...newErrors]));
    return () => globalErrorHandler.forget();
  });

  return [errors, () => setErrors([])];
}
