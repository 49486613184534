import { Modal } from 'src/components';
import { useErrorHandling } from './ErrorHandler';

export function ErrorModal() {
  const [errors, clear] = useErrorHandling();

  return (
    <Modal important isOpen={!!errors.length} onClose={clear}>
      <div className='z-50 text-red-500'>
        {errors.map((e, i) => (
          <div className='whitespace-pre' key={i}>
            {e}
          </div>
        ))}
      </div>
    </Modal>
  );
}
