import { KnownNameType } from 'src/types';

export function mapTypeToString(type: KnownNameType) {
  switch (type) {
    case KnownNameType.Buyer:
      return 'Cumparator';

    case KnownNameType.Seller:
      return 'Vanzator';

    default:
      return '???';
  }
}
