import { Table, TableHeaders } from 'src/components';
import { useModal } from 'src/modal';
import { UpdateForm } from './UpdateForm';
import { AddForm } from './AddForm';
import { GetAllDocument, GetAllQuery, useDeleteByIdMutation, useGetAllQuery } from './requests.generated';

export function MaterialDefinitionsPage() {
  const headers: TableHeaders<GetAllQuery['materialDefinitions']['all'][0]> = [
    { renderCell: (row) => row.name, title: 'Nume' },
    { renderCell: (row) => row.unit, title: 'Unitate de masura' },
  ];

  const { show } = useModal();
  const [deleteMutation] = useDeleteByIdMutation();

  const { data } = useGetAllQuery();

  return (
    <div className='flex flex-col gap-3'>
      <div>
        <AddForm />
      </div>

      <Table
        data={data?.materialDefinitions.all}
        headers={headers}
        onEdit={(item) => show(<UpdateForm id={item.id} />)}
        onDelete={(item) => deleteMutation({ variables: { id: item.id }, refetchQueries: [GetAllDocument] })}
      />
    </div>
  );
}
