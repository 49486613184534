import { PropsWithChildren, useCallback } from 'react';
import { WithoutKey } from 'src/utils';
import { Button } from '../Button';
import { ControlledDateRangePicker, ControlledDateRangePickerProps } from './ControlledDateRangePicker';

import { DateRangeOrDefault } from './DateRangeOrDefault';

export type DateRangePickerProps = WithoutKey<ControlledDateRangePickerProps, 'interval'> & {
  interval: DateRangeOrDefault;
  hide3Months?: boolean;
  hideThisWeek?: boolean;
  hideLastWeek?: boolean;
};

export function DateRangePicker({
  onChange,
  interval,
  hide3Months,
  hideThisWeek,
  hideLastWeek,
  ...props
}: PropsWithChildren<DateRangePickerProps>) {
  const setIntervalFactory = (method: () => DateRangeOrDefault) => () => onChange(method());

  const onCalendarChange = useCallback(
    (interval: DateRangeOrDefault) =>
      onChange(interval.replaceStart(interval.start.startOfDay()).replaceEnd(interval.end.endOfDay())),
    [],
  );

  return (
    <div className='flex my-2'>
      <ControlledDateRangePicker {...props} onChange={onCalendarChange} interval={interval} />
      <Button onClick={setIntervalFactory(DateRangeOrDefault.getToday)} variant='blue'>
        Azi
      </Button>
      {!hide3Months && (
        <Button onClick={setIntervalFactory(DateRangeOrDefault.getThreeMonths)} variant='blue'>
          3 Luni
        </Button>
      )}
      {!hideThisWeek && (
        <Button onClick={setIntervalFactory(DateRangeOrDefault.getThisWeek)} variant='blue'>
          Saptamana curenta
        </Button>
      )}
      {!hideLastWeek && (
        <Button onClick={setIntervalFactory(DateRangeOrDefault.getLastWeek)} variant='blue'>
          Saptamana trecuta
        </Button>
      )}
      <Button onClick={setIntervalFactory(DateRangeOrDefault.getTotal)} variant='blue'>
        Total
      </Button>
    </div>
  );
}
