import { DateRangePicker, Table, TableHeaders } from 'src/components';
import { useGetAllQuery } from './requests.generated';
import { MaterialStock } from 'src/types';
import { DateRangeOrDefault } from 'src/components/DatePicker/DateRangeOrDefault';
import { useDateRange } from 'src/hooks/useDateRange';

export function MaterialStockPage() {
  const [variables, interval, setRange] = useDateRange(DateRangeOrDefault.getToday());
  const headers: TableHeaders<MaterialStock> = [
    { renderCell: (row) => row.materialName, title: 'Nume material' },
    { renderCell: (row) => row.finalStock, title: 'Stoc final' },
    { renderCell: (row) => row.initialStock, title: 'Stoc initial' },
    { renderCell: (row) => row.bought, title: 'Cumparat' },
    { renderCell: (row) => row.used, title: 'Utilizat' },
  ];

  const { data } = useGetAllQuery({ variables });

  return (
    <div className='flex flex-col'>
      <DateRangePicker label='Interval' onChange={setRange} interval={interval} />
      <div>
        <Table headers={headers} data={data?.stocks.materialStock} />
      </div>
    </div>
  );
}
