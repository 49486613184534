import Cookies from 'js-cookie';
import StringHash from 'string-hash';

const NAME = '0x0123456789ABCDEF';
export const EXPECTED_HASH = '3864356286';
// const pass = 'jDeN8vby8Jsv3QkzHUSZ32xb';

const getHash = (code: string | null) => `${StringHash(code || '')}`;

const isCodeCorrect = (code: string | null) => {
  const isCorrect = getHash(code) === EXPECTED_HASH;

  Cookies.set(NAME, EXPECTED_HASH);

  return isCorrect;
};

export const isAuthorized = (): boolean => isCodeCorrect(localStorage.getItem(NAME));

export const authorize = (code: string) => {
  if (isCodeCorrect(code)) {
    localStorage.setItem(NAME, code);
    return true;
  }

  return false;
};
