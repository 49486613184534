import { useEffect, useState } from 'react';
import { Button, DateRangePicker, Table } from 'src/components';
import { DateRangeOrDefault } from 'src/components/DatePicker/DateRangeOrDefault';
import { useDateRange } from 'src/hooks/useDateRange';
import { useGetAllQuery, useGetWorkersQuery } from './requests.generated';

export function WorkersPage() {
  const [variables, interval, setRange] = useDateRange(DateRangeOrDefault.getToday());
  const { data: workersData } = useGetWorkersQuery();

  const [workerIds, setWorkerIds] = useState<string[]>([]);
  const { loading, data } = useGetAllQuery({ variables: { ...variables, workerIds } });

  useEffect(() => {
    setWorkerIds(workersData?.knowNames.all.map((x) => x.value) || []);
  }, [workersData]);

  return (
    <div className='flex flex-col'>
      <DateRangePicker hide3Months label='Interval' onChange={setRange} interval={interval} />
      <div>
        {workersData?.knowNames.all.map((w) => {
          const isSelected = workerIds.includes(w.value);

          function handleToggle(val: string) {
            return function () {
              if (workerIds.includes(val)) {
                setWorkerIds(workerIds.filter((f) => f !== val));
              } else {
                setWorkerIds([...workerIds, val]);
              }
            };
          }

          return (
            <button
              onClick={handleToggle(w.value)}
              className={`py-1.5 px-4 text-sm font-medium hover:opacity-50 border-2 rounded-md border-black bg-white m-2 ${
                isSelected && 'bg-yellow-200'
              } font-bold text-lg`}
            >
              {w.label}
            </button>
          );
        })}
        <Button onClick={() => setWorkerIds([])}>Nimeni</Button>
        <Button onClick={() => setWorkerIds(workersData?.knowNames.all.map((x) => x.value) || [])}>Toti</Button>
      </div>
      <div className='flex flex-wrap gap-4 content-stretch'>
        {data?.workerResults.results.map((w) => (
          <div className='flex flex-col gap-2 mb-2 bg-gray-50 shadow-md rounded-lg h-fit'>
            <div className='bg-blue-300 font-bold text-center rounded-t-lg p-1'>{w.name}</div>
            <Table
              headers={[
                {
                  title: 'Ziua',
                  renderCell: (x) => (
                    <div className='flex flex-col gap-2'>
                      <div>{x.day}</div>
                      <div>{x.dayOfWeek}</div>
                    </div>
                  ),
                },
                {
                  title: 'Produse',
                  renderCell: (x) => (
                    <div>
                      {x.products.map((y) => (
                        <div className='grid grid-cols-2 gap-2' key={y.name + y.amount}>
                          <div>{y.name}</div>
                          <div>{y.amount}</div>
                        </div>
                      ))}
                      <div className='grid grid-cols-2 gap-2 bg-green-100'>
                        <div>Total</div>
                        <div>{x.total}</div>
                      </div>
                    </div>
                  ),
                },
              ]}
              data={w.days}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
