import { DateField, FormContextProvider, NumericField, SelectField, TextField } from 'src/form';
import { useModal } from 'src/modal';
import { GetAllDocument, useGetOneQuery, useGetPrerequisitesQuery, useUpdateMutation } from './requests.generated';

export function UpdateForm({ id }: any) {
  const { close } = useModal();
  const [updateMutation] = useUpdateMutation();
  const { data: entity, loading: entityLoading } = useGetOneQuery({ variables: { id } });
  const { data: prerequisites, loading: prerequisitesLoading } = useGetPrerequisitesQuery();

  return entityLoading || prerequisitesLoading ? null : (
    <FormContextProvider
      onSubmit={(request: any) =>
        updateMutation({ variables: { request }, onCompleted: close, refetchQueries: [GetAllDocument] })
      }
      defaultValues={entity?.materialTransactions.one}
    >
      <DateField name='receivedAt' label={'Data primire'} />
      <SelectField name='receivedFromId' label={'Nume furnizor'} options={prerequisites!.sellers.all} />
      <SelectField name='materialDefinitionId' label={'Nume material'} options={prerequisites!.materials.all} />
      <NumericField name='quantity' label={'Cantitate'} />
    </FormContextProvider>
  );
}
