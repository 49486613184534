import { useMemo, useState } from 'react';
import { Button, DateRangePicker, Dropdown } from 'src/components';
import { DateRangeOrDefault } from 'src/components/DatePicker/DateRangeOrDefault';
import { useDateRange } from 'src/hooks/useDateRange';
import { useModal } from 'src/modal';
import { ProductTransactionDetailsClassification } from 'src/types';
import { AddForm } from './AddForm';
import { Kpis } from './Kpis';
import { useGetAllQuery, useGetPrerequisitesQuery } from './requests.generated';
import { TransactionBoxByDay } from './TransactionBoxByDay';

export function ProductTransactionsPage() {
  const [variables, interval, setRange] = useDateRange(DateRangeOrDefault.getThreeMonths());
  const [sellerIds, setSellerIds] = useState<{ label: any; value: any }[]>([]);

  const [classifications, setClassifications] = useState<ProductTransactionDetailsClassification[]>([
    ProductTransactionDetailsClassification.Green,
    ProductTransactionDetailsClassification.Red,
    ProductTransactionDetailsClassification.Yellow,
    ProductTransactionDetailsClassification.Unknown,
  ]);
  const { data: buyers } = useGetPrerequisitesQuery();

  const variablesMemo = useMemo(
    () => ({
      ...variables,
      classifications,
      sellerIds: (sellerIds.length ? sellerIds : buyers?.buyers.all || []).map((s) => s.value),
    }),
    [variables, classifications, sellerIds, buyers],
  );

  const { data } = useGetAllQuery({ variables: variablesMemo });

  const { show, close } = useModal();
  return (
    <div className='flex flex-col gap-3'>
      <Kpis
        classifications={classifications}
        setClassifications={setClassifications}
        data={data?.productTransactions.all}
      />
      <DateRangePicker label='Interval' onChange={setRange} interval={interval} />
      <div className='flex gap-2'>
        <Button onClick={() => show(<AddForm onAdd={close} />)}>Adauga</Button>
        <div className='w-64'>
          <Dropdown
            placeholder='Cumparatori'
            value={sellerIds}
            defaultValue={sellerIds}
            onChange={setSellerIds}
            isMulti
            options={buyers?.buyers.all || []}
          />
        </div>
      </div>
      <TransactionBoxByDay transactions={data?.productTransactions.all ?? []} />
    </div>
  );
}
