import { useDelayedRender } from 'src/hooks/useDelayedRender';
import { Button } from '../Button';
import { ConfirmationButton } from '../ConfirmationButton';
import { tdClassNames } from './Table';
import { TableHeader } from './TableHeader';

export type TableRowProps<T> = {
  onEdit?: (item: T) => void;
  onDelete?: (item: T) => void;
  headers: TableHeader<T>[];
  hasActions: boolean;
  hasEdit: boolean;
  hasDelete: boolean;
  index: number;
  item: T;
};

export function TableRow<T>({
  index: idx,
  item,
  headers,
  hasActions,
  hasEdit,
  hasDelete,
  onDelete,
  onEdit,
}: TableRowProps<T>) {
  const shouldRender = useDelayedRender(idx);
  if (!shouldRender) {
    return null;
  }

  return (
    <tr key={idx} className='bg-white border-b border-l-4 box-border border-l-transparent'>
      {headers.map((header, index) => (
        <td key={index} className={tdClassNames}>
          {header.renderCell(item)}
        </td>
      ))}
      {hasActions && (
        <td className={tdClassNames}>
          {hasEdit && (
            <Button onClick={() => onEdit!(item)} variant='blue'>
              Modifica
            </Button>
          )}
          {hasDelete && (
            <ConfirmationButton
              confirmationPopupProps={{
                title: 'Sigur vrei sa stergi?',
                message: 'Daca vei sterge asta, tot ce ii legat de ea se va sterge!',
              }}
              buttonProps={{ onClick: () => onDelete!(item), children: 'Sterge', variant: 'red' }}
            />
          )}
        </td>
      )}
    </tr>
  );
}
