import { createContext, PropsWithChildren, ReactNode, useContext, useState } from 'react';
import ReactModal from 'react-modal';
import { Modal } from 'src/components';
import { EmptyMethod } from 'src/utils';

ReactModal.setAppElement('#root');

type ModalContextType = {
  show: (content: ReactNode) => void;
  close: () => void;
};

const ModalContext = createContext<ModalContextType>({ show: EmptyMethod, close: EmptyMethod });

export function ModalContextProvider({ children }: PropsWithChildren) {
  const [content, setContent] = useState<ReactNode>(null);

  const show = (content: ReactNode) => setContent(content);
  const close = () => setContent(null);

  return (
    <ModalContext.Provider
      value={{
        show,
        close,
      }}
    >
      {children}
      <Modal onClose={close} isOpen={!!content}>
        {content}
      </Modal>
    </ModalContext.Provider>
  );
}

export function useModal() {
  return useContext(ModalContext) as ModalContextType;
}
