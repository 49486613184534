import { PropsWithChildren } from 'react';
import { Controller } from 'react-hook-form';
import { FieldWrapper } from '../components';
import { useFormContext } from '../FormProvider';
import { ProductTransactionDetailsClassification } from 'src/types';

export interface ButtonGroupFieldProps {
  options: { label: string; value: any; classification?: ProductTransactionDetailsClassification }[];
  name: string;
  label: string;
  important?: boolean;
}

const baseClasses = 'py-2 px-4 text-sm font-medium hover:opacity-50';

function getClassNames(index: number, length: number, isSelected: boolean) {
  const isFirst = index === 0;
  const isLast = index === length - 1;

  let base = baseClasses;
  if (isFirst) {
    base += ' border rounded-l-lg';
  }
  if (isLast) {
    base += ' border rounded-r-lg';
  }

  if (!isFirst && !isLast) {
    base += ' border';
  }

  if (isSelected) {
    base += ' border-gray-900 bg-gray-900 text-white';
  } else {
    base += ' bg-white text-gray-900';
  }

  return base;
}

export function ButtonGroupField({ label, name, important, options }: PropsWithChildren<ButtonGroupFieldProps>) {
  const { control } = useFormContext();
  return (
    <FieldWrapper name={name} label={label} important={important}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => {
          return (
            <div className='inline-flex rounded-md shadow-sm' role='group'>
              {options.map((f, idx) => {
                return (
                  <button
                    key={idx}
                    type='button'
                    className={getClassNames(idx, options.length, f.value === value) + ` ${f.value}`}
                    onClick={() => onChange(f.value)}
                  >
                    {f.label}
                  </button>
                );
              })}
            </div>
          );
        }}
      />
    </FieldWrapper>
  );
}


function handleToggle(values: any[] | undefined, value: any) {
  if (!values || !values.length) {
    return [value]
  }

  if (values.includes(value)) {
    return values.filter(v => v !== value)
  }

  return [...values, value]
}

export function MultipleButtonGroupField({ label, name, important, options }: PropsWithChildren<ButtonGroupFieldProps>) {
  const { control } = useFormContext();
  return (
    <FieldWrapper name={name} label={label} important={important}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => {
          const ensuredValue = value || []
          return (
            <div className='inline-flex rounded-md shadow-sm' role='group'>
              {options.map((f, idx) => {
                return (
                  <button
                    key={idx}
                    type='button'
                    className={getClassNames(idx, options.length, ensuredValue.includes(f.value)) + ` ${f.value}`}
                    onClick={() => onChange(handleToggle(ensuredValue, f.value))}
                  >
                    {f.label}
                  </button>
                );
              })}
            </div>
          );
        }}
      />
    </FieldWrapper>
  );
}
