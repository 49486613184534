import { Path } from 'react-hook-form';

export function getDeepPropertyValue<T>(obj: T, key: Path<T>) {
  let currentObject: any = obj;
  let stringKey = key.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  stringKey = stringKey.replace(/^\./, ''); // strip a leading dot
  let stringKeyParts = stringKey.split('.');
  for (var i = 0, n = stringKeyParts.length; i < n; ++i) {
    var stringKeyPart = stringKeyParts[i];
    try {
      if (stringKeyPart in currentObject) {
        currentObject = currentObject[stringKeyPart];
      } else {
        return;
      }
    } catch (err) {
      return;
    }
  }
  return currentObject;
}
