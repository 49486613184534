import { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { globalErrorHandler } from './ErrorHandler';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    globalErrorHandler.notify(
      graphQLErrors.flatMap((e: any) => [e.message, e.extensions?.message, e.extensions?.stackTrace].filter((x) => x)),
    );
  }

  if (networkError) {
    globalErrorHandler.notify([networkError.message]);
  }
});

const httpLink = new HttpLink({ uri: '/graphql' });

const client = new ApolloClient({
  uri: '/graphql/',
  cache: new InMemoryCache({ addTypename: false }),
  link: from([errorLink, httpLink]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});

export function GraphQlProvider({ children }: ComponentArgs) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
