import { useFormState } from 'react-hook-form';
import { getDeepPropertyValue } from 'src/utils';
import { useFormContext } from '../FormProvider';

export function ErrorMessage({ name }: { name: string }) {
  const { control } = useFormContext();
  const { errors, touchedFields } = useFormState({ control });
  const error = getDeepPropertyValue<any>(errors, name);
  const isTouched = getDeepPropertyValue(touchedFields, name);

  if (!isTouched) return null;
  if (!error?.message) return null;

  return <div className='text-sm text-red-400'>{error.message.toString()}</div>;
}
