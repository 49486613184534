import { FormContextProvider, TextField } from 'src/form';
import { useModal } from 'src/modal';
import { GetAllDocument, useGetOneQuery, useUpdateMutation } from './requests.generated';

export function UpdateForm({ id }: any) {
  const { close } = useModal();

  const { data, loading } = useGetOneQuery({ variables: { id } });
  const [updateMutation] = useUpdateMutation();

  if (loading) {
    return null;
  }

  return (
    <FormContextProvider
      defaultValues={data?.materialDefinitions.one}
      onSubmit={(request: any) =>
        updateMutation({ variables: { request }, onCompleted: close, refetchQueries: [GetAllDocument] })
      }
    >
      <TextField name='name' label={'Nume'} />
      <TextField name='unit' label={'Unitate de masura'} />
    </FormContextProvider>
  );
}
