import { ButtonHTMLAttributes, memo, useMemo } from 'react';

export interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: 'green' | 'blue' | 'red';
  href?: string;
  target?: '_blank';
  Icon: (props: React.ComponentProps<'svg'>) => JSX.Element;
}

function getClassNames(color: IconButtonProps['color']): string {
  switch (color) {
    case 'green':
      return 'hover:border-green-500 hover:bg-green-500 active:bg-green-600';
    case 'red':
      return 'hover:border-red-500 hover:bg-red-500 active:bg-red-600';
    case 'blue':
    default:
      return 'hover:border-blue-500 hover:bg-blue-500 active:bg-blue-600';
  }
}

export const IconButton = memo(function ({ color = 'blue', Icon, href, target, ...rest }: IconButtonProps) {
  const className = `p-2 transition-all duration-150 ease-linear border rounded outline-none border-slate-500 text-slate-500  hover:text-white focus:outline-none ${getClassNames(
    color,
  )}`;

  const icon = useMemo(() => <Icon className='w-6 h-6' />, [Icon]);

  if (href) {
    return (
      <a {...(rest as any)} className={className} href={href} target={target}>
        {icon}
      </a>
    );
  }

  return (
    <button type='button' {...rest} className={className}>
      {icon}
    </button>
  );
});
