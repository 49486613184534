import React, { PropsWithChildren } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { classNames } from '../utils/combineClasses';

export interface NavBarItemProps {
  title: string;
  path: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
}

export function NavBarItem({ title, path, icon }: PropsWithChildren<NavBarItemProps>) {
  const Icon = icon;
  const { pathname } = useLocation();
  const isActive = pathname.includes(path);
  const navigate = useNavigate();

  const spanClassName = classNames(
    'inline-flex select-none cursor-pointer p-4 rounded-t-lg border-b-2  hover:text-gray-600 hover:border-gray-300 group',
    isActive ? 'hover:text-blue-600 text-blue-600 hover:border-blue-600 border-blue-600' : 'border-transparent',
  );

  return (
    <li className='mr-2' onClick={() => navigate(path)}>
      <span className={spanClassName}>
        <Icon className='w-5 h-5 mr-1' />
        {title}
      </span>
    </li>
  );
}
