import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllQueryVariables = Types.Exact<{
  start: Types.Scalars['DateTime'];
  end: Types.Scalars['DateTime'];
  categories: Array<Types.ProductCategory> | Types.ProductCategory;
}>;


export type GetAllQuery = { stocks: { productStock: Array<{ productName: string, initialStock: string, finalStock: string, produced: string, sold: string, category: Types.ProductCategory }> } };


export const GetAllDocument = gql`
    query getAll($start: DateTime!, $end: DateTime!, $categories: [ProductCategory!]!) {
  stocks {
    productStock(start: $start, end: $end, categories: $categories) {
      productName
      initialStock
      finalStock
      produced
      sold
      category
    }
  }
}
    `;

/**
 * __useGetAllQuery__
 *
 * To run a query within a React component, call `useGetAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      categories: // value for 'categories'
 *   },
 * });
 */
export function useGetAllQuery(baseOptions: Apollo.QueryHookOptions<GetAllQuery, GetAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllQuery, GetAllQueryVariables>(GetAllDocument, options);
      }
export function useGetAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllQuery, GetAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllQuery, GetAllQueryVariables>(GetAllDocument, options);
        }
export type GetAllQueryHookResult = ReturnType<typeof useGetAllQuery>;
export type GetAllLazyQueryHookResult = ReturnType<typeof useGetAllLazyQuery>;
export type GetAllQueryResult = Apollo.QueryResult<GetAllQuery, GetAllQueryVariables>;