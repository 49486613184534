import { format } from 'date-fns';

export const minDate = new Date(2000, 1, 1);
export const maxDate = new Date(3000, 1, 1);

export function formatDate(date: Date | null | undefined) {
  if (!date || isNaN(date.getDate()) || isNaN(date.getMonth()) || isNaN(date.getFullYear())) {
    return 'Alege data';
  }

  return format(date, 'dd-MM-yyyy');
}

export function formatDateTime(date: string) {
  return format(new Date(date), 'dd-MM-yyyy HH:mm:ss');
}

export function getYearsOptions(): {
  value: string;
  label?: string | null | undefined;
}[] {
  const currentYear = new Date().getFullYear();
  return [currentYear - 4, currentYear - 3, currentYear - 2, currentYear - 1, currentYear, currentYear + 1, currentYear + 2, currentYear + 3, currentYear + 4]
    .map(year => ({ label: year.toString(), value: year.toString() }));
}