import { PropsWithChildren, useMemo } from 'react';
import { Table, TableHeaders } from 'src/components';
import { GetAllQuery } from './requests.generated';

export interface MaterialUsagesProps {
  usages: GetAllQuery['productProductions']['all'][0]['usages'];
}

export function MaterialUsages({ usages }: PropsWithChildren<MaterialUsagesProps>) {
  const headers: TableHeaders<MaterialUsagesProps['usages'][0]> = [
    { renderCell: (row) => row.name, title: 'Material' },
    { renderCell: (row) => row.quantity, title: 'Cantitate' },
  ];

  return <Table headers={headers} data={usages} />;
}
