type Props = {
  name: string;
  active: boolean;
  onClick: Function;
  className?: string;
  entriesCount?: number;
  totalPrice?: number;
  percentage?: number;
};

export function KpiCard({ active, className, name, onClick, entriesCount, totalPrice, percentage }: Readonly<Props>) {
  return (
    <div
      className={` flex-shrink-0 w-48 h-32 flex flex-col justify-center items-center shadow-md hover:shadow-lg cursor-pointer select-none ${active ? 'opacity-100 ' : 'opacity-40'
        } ${className}`}
      onClick={onClick as any}
    >
      <div className='text-lg border-b-2'>{name}</div>
      {!!entriesCount && <div >{entriesCount || 0}</div>}
      {!!totalPrice && <div >{totalPrice.toFixed(2) || 0} MDL</div>}
      {!!percentage && <div >{(percentage || 0).toFixed(2) || 0} %</div>}
    </div>
  );
}
