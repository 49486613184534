import { from } from 'linq-to-typescript';
import { memo, PropsWithChildren, useMemo } from 'react';
import { Text } from 'src/components';
import { TransactionBox } from './TransactionBox';
import { GetAllQuery } from './requests.generated';

export interface TransactionBoxByDayProps {
  transactions: GetAllQuery['productTransactions']['all'];
}

export const TransactionBoxByDay = memo(({ transactions }: PropsWithChildren<TransactionBoxByDayProps>) => {
  const groupedTransactions = useMemo(
    () =>
      from(transactions)
        .groupBy((x) => x.soldAt)
        .toArray(),
    [transactions],
  );

  let id = 0;

  return (
    <div>
      {groupedTransactions.map((x) => (
        <div key={x.key} className='my-4'>
          <Text variant='subtitle'>{x.key}</Text>
          <div className='flex flex-wrap gap-4'>
            {x.select((y) => <TransactionBox index={id++} key={y.id} transaction={y} />).toArray()}
          </div>
        </div>
      ))}
    </div>
  );
});
