import { Button } from 'src/components';
import {
  ArrayField,
  ButtonGroupField,
  DateField,
  FormContextProvider,
  NumericField,
  SelectField,
  TextField,
} from 'src/form';
import { GetAllDocument, useAddMutation, useGetPrerequisitesQuery } from './requests.generated';
import { DeliveryState, ProductTransactionDetailsClassification } from 'src/types';
import { TotalProductPrice, TotalTransactionPrice } from './TotalPrice';
import { useRef } from 'react';
import { DeliveredField } from './DeliveredField';

type Props = {
  onAdd: () => void;
};

export function AddForm({ onAdd }: Props) {
  const { data: prerequisites, loading: prerequisitesLoading } = useGetPrerequisitesQuery();
  const [addMutation] = useAddMutation();
  const previewRef = useRef(false);

  return prerequisitesLoading ? null : (
    <FormContextProvider
      onSubmit={(request: any) =>
        addMutation({
          variables: { request },
          onCompleted: (data) => {
            onAdd();
            if (previewRef.current) {
              window.open(`/api/productTransactionDetails/invoice/${data.productTransactions.add}`, '_blank');
            }
          },
          refetchQueries: [GetAllDocument],
        })
      }
      submitButtonTemplate={(isDisabled) => {
        return (
          <>
            <Button type='submit' disabled={isDisabled} onClick={() => (previewRef.current = true)}>
              Salveaza si vizualizeaza
            </Button>

            <Button type='submit' disabled={isDisabled} onClick={() => (previewRef.current = false)}>
              Salveaza
            </Button>
          </>
        );
      }}
      defaultValues={{
        soldAt: new Date(),
        transactions: [{}],
        deliveryState: DeliveryState.NotDelivered,
        classification: ProductTransactionDetailsClassification.Unknown,
      }}
    >
      <div className='flex gap-4'>
        <DateField name='soldAt' label='Data' important />
        <SelectField name='soldToId' label='Cumparator' options={prerequisites!.buyers.all} important />
        <TextField name='comment' label='Comentariu' important />
      </div>
      <ButtonGroupField
        name='classification'
        label='Clasificare'
        options={[
          { label: 'Nimic', value: ProductTransactionDetailsClassification.Unknown },
          { label: 'Verde', value: ProductTransactionDetailsClassification.Green },
          { label: 'Galben', value: ProductTransactionDetailsClassification.Yellow },
          { label: 'Rosu', value: ProductTransactionDetailsClassification.Red },
        ]}
      />
      <DeliveredField />
      <ArrayField
        name='transactions'
        itemTemplate={(getName, remove, index) => (
          <div className='w-full mb-[-24px]'>
            <div className='grid grid-cols-7 gap-4'>
              <div className='col-span-3'>
                <SelectField
                  name={getName('productDefinitionId')}
                  label='Nume'
                  options={prerequisites!.products.all}
                  hideLabel={!!index}
                />
              </div>
              <div className='col-span-1'>
                <NumericField name={getName('quantity')} label='Cantitate' hideLabel={!!index} />
              </div>
              <div className='col-span-1'>
                <NumericField name={getName('price')} label='Pret' hideLabel={!!index} />
              </div>
              <div className='col-span-1'>
                <TotalProductPrice getName={getName} hideLabel={!!index} />
              </div>
              <div className='flex mt-auto mb-6 h-11'>
                <Button variant='red' onClick={remove}>
                  Sterge
                </Button>
              </div>
            </div>
          </div>
        )}
        wrapperTemplate={(children, append) => (
          <div className='w-full mb-6'>
            {children}
            <div className='flex justify-start w-full my-4'>
              <Button variant='green' type='button' onClick={() => append()}>
                Adauga produs
              </Button>
            </div>
            <div className='grid grid-cols-7 gap-4'>
              <div className='col-span-5' />
              <div className='col-span-2'>
                <TotalTransactionPrice />
              </div>
            </div>
          </div>
        )}
      />
    </FormContextProvider>
  );
}
