import { differenceInDays, setDefaultOptions, startOfWeek } from 'date-fns';
import { PropsWithChildren } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { DateOrDefault } from './DateOrDefault';
import { DatePickerHeader } from './DatePickerHeader';
import { DatePickerTrigger } from './DatePickerTrigger';

function weekIndexOf(date: Date) {
  const start = startOfWeek(date);
  const diff = differenceInDays(date, start);
  return diff;
}

const days = ['Du', 'Lu', 'Ma', 'Mi', 'Jo', 'Vi', 'Sa'];
const months = [
  'Ianuarie',
  'Februarie',
  'Martie',
  'Aprilie',
  'Mai',
  'Iunie',
  'Iulie',
  'August',
  'Septembrie',
  'Octombire',
  'Noimbrie',
  'Decembrie',
];

const locale = {
  localize: {
    day: (n: number) => days[n],
    month: (n: number) => months[n],
  },
  formatLong: {
    date: () => 'dd-MM-yyyy',
  },
};

registerLocale('mylocale', locale as any);
setDefaultOptions({ weekStartsOn: 1 });

export interface ControlledDatePickerProps {
  date: DateOrDefault;
  startDate?: DateOrDefault;
  endDate?: DateOrDefault;
  onChange: (date: DateOrDefault) => void;
  important?: boolean;
}

export function ControlledDatePicker({
  date,
  startDate,
  endDate,
  onChange,
  important,
}: PropsWithChildren<ControlledDatePickerProps>) {
  return (
    <ReactDatePicker
      selected={date.date || null}
      onChange={(date) => onChange(DateOrDefault.from(date))}
      selectsStart
      startDate={startDate?.date}
      endDate={endDate?.date}
      nextMonthButtonLabel='>'
      previousMonthButtonLabel='<'
      weekDayClassName={(date) => {
        const index = weekIndexOf(date);
        if (index === 6 || index === 0) {
          return 'text-red-300';
        }
        return '';
      }}
      //@ts-ignore
      customInput={<DatePickerTrigger date={date} important={important} />}
      renderCustomHeader={DatePickerHeader}
      calendarStartDay={1}
      locale={'mylocale'}
    />
  );
}
