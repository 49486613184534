import { ProductCategory } from "src/types";
import { KpiCard } from "../productTransactions/KpiCard";

type Props = React.PropsWithChildren & {
    categories: ProductCategory[];
    setCategory: (
        setFunction: (current: ProductCategory[]) => ProductCategory[],
    ) => void;
};

function toggle(
    setCategory: Props['setCategory'],
    set: Set<ProductCategory>,
    category: ProductCategory,
) {
    return function () {
        setCategory((categories) => {
            if (set.has(category)) {
                return categories.filter((c) => c !== category);
            }
            return [...categories, category];
        });
    };
}

function getProps(
    setClassifications: Props['setCategory'],
    set: Set<ProductCategory>,
    category: ProductCategory,
) {
    return {
        onClick: toggle(setClassifications, set, category),
        active: set.has(category),
        className: category,
    };
}

export function Kpis({ categories, setCategory }: Props) {

    const set = new Set(categories);

    return (
        <div className='flex flex-wrap gap-6'>
            <KpiCard
                name="Adezivi"
                {...getProps(setCategory, set, ProductCategory.Adezivi)}
            />
            <KpiCard
                name="Grunduri"
                {...getProps(setCategory, set, ProductCategory.Grunduri)}
            />
            <KpiCard
                name="Vopsele"
                {...getProps(setCategory, set, ProductCategory.Vopsele)}
            />
            <KpiCard
                name="Altele"
                {...getProps(setCategory, set, ProductCategory.Altele)}
            />
            <KpiCard

                name="Toate"
                active={categories.length === 4}
                onClick={() =>
                    setCategory(() => [
                        ProductCategory.Adezivi,
                        ProductCategory.Altele,
                        ProductCategory.Grunduri,
                        ProductCategory.Vopsele,
                    ])
                }
            />
            <KpiCard
                name='Nimic'
                active={categories.length === 0}
                onClick={() => setCategory(() => [])}
            />
        </div>
    );
}
