import { DateRangePicker, Table, TableHeaders } from 'src/components';
import { useGetAllQuery } from './requests.generated';
import { ProductCategory, ProductStock } from 'src/types';
import { DateRangeOrDefault } from 'src/components/DatePicker/DateRangeOrDefault';
import { useDateRange } from 'src/hooks/useDateRange';
import { useMemo, useState } from 'react';
import { Kpis } from './Kpis';

export function ProductStockPage() {
  const [variables, interval, setRange] = useDateRange(DateRangeOrDefault.getToday());

  const headers: TableHeaders<ProductStock> = [
    { renderCell: (row) => row.productName, title: 'Nume produs' },
    { renderCell: (row) => row.finalStock, title: 'Stoc final' },
    { renderCell: (row) => row.initialStock, title: 'Stoc initial' },
    { renderCell: (row) => row.produced, title: 'Produs' },
    { renderCell: (row) => row.sold, title: 'Vandut' },
    { renderCell: (row) => row.category, title: 'Categorie' },
  ];

  const [categories, setCategory] = useState<ProductCategory[]>([

  ]);
  const variablesMemo = useMemo(() => ({ ...variables, categories }), [categories, variables]);

  const { data } = useGetAllQuery({ variables: variablesMemo });

  return (
    <div className='flex flex-col'>
      <Kpis categories={categories} setCategory={setCategory} />
      <DateRangePicker label='Interval' onChange={setRange} interval={interval} />
      <div>
        <Table headers={headers} data={data?.stocks.productStock} />
      </div>
    </div>
  );
}
