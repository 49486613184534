import { maxDate, minDate } from './DateHelpers';
import { DateOrDefault } from './DateOrDefault';

export class DateRangeOrDefault {
  constructor(public start: DateOrDefault, public end: DateOrDefault) {}

  get() {
    return { start: this.start.getOrDefault(minDate), end: this.end.getOrDefault(maxDate) };
  }

  replaceStart(start: DateOrDefault) {
    if (this.start.date !== start.date) {
      return new DateRangeOrDefault(start, this.end);
    }
    return this;
  }

  replaceEnd(end: DateOrDefault) {
    if (this.end.date !== end.date) {
      return new DateRangeOrDefault(this.start, end);
    }
    return this;
  }

  public static getToday = () =>
    new DateRangeOrDefault(DateOrDefault.getToday().startOfDay(), DateOrDefault.getToday().endOfDay());
  public static getTotal = () => new DateRangeOrDefault(DateOrDefault.from(), DateOrDefault.from());
  public static getThreeMonths = () =>
    new DateRangeOrDefault(DateOrDefault.getToday().addMonths(-3), DateOrDefault.from());

  public static getThisWeek = () =>
    new DateRangeOrDefault(DateOrDefault.getToday().startOfWeek(), DateOrDefault.getToday().endOfWeek());

  public static getLastWeek = () =>
    new DateRangeOrDefault(
      DateOrDefault.getToday().startOfWeek().addWeeks(-1),
      DateOrDefault.getToday().endOfWeek().addWeeks(-1),
    );

  public static Default = new DateRangeOrDefault(new DateOrDefault(), new DateOrDefault());
}
