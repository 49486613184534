import { useFormContext } from '../FormProvider';
import { HtmlInputField } from './HtmlInputField';

export type NumericFieldProps = React.InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  label: string;
  hideLabel?: boolean;
};

export function NumericField(props: NumericFieldProps) {
  const { register } = useFormContext();
  return (
    <HtmlInputField
      {...props}
      {...register(props.name, { valueAsNumber: true })}
      skipRegister
      type='text'
      inputMode='decimal'
      step='any'
      min={0}
    />
  );
}
