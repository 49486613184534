import { Box, BoxContainer, Button } from 'src/components';
import {
  ArrayField,
  ButtonGroupField,
  DateField,
  FormContextProvider,
  NumericField,
  SelectField,
  TextField,
} from 'src/form';
import { GetAllDocument, useGetOneQuery, useGetPrerequisitesQuery, useUpdateMutation } from './requests.generated';
import { ProductTransactionDetailsClassification } from 'src/types';
import { TotalProductPrice, TotalTransactionPrice } from './TotalPrice';
import { useRef } from 'react';
import { DeliveredField } from './DeliveredField';

type Props = {
  id: string;
  onUpdate: () => void;
};

export function UpdateForm({ id, onUpdate }: Props) {
  const [updateMutation] = useUpdateMutation();
  const { data: prerequisites, loading: prerequisitesLoading } = useGetPrerequisitesQuery();
  const { data: entity, loading: entityLoading } = useGetOneQuery({ variables: { id } });
  const previewRef = useRef(false);

  return entityLoading || prerequisitesLoading ? null : (
    <div className='flex flex-wrap gap-2'>
      <FormContextProvider
        defaultValues={entity?.productTransactions.one}
        onSubmit={(request: any) =>
          updateMutation({
            variables: { request },
            onCompleted: (data) => {
              onUpdate();
              if (previewRef.current) {
                window.open(`/api/productTransactionDetails/invoice/${id}`, '_blank');
              }
            },
            refetchQueries: [GetAllDocument],
          })
        }
        submitButtonTemplate={(isDisabled) => {
          return (
            <>
              <Button type='submit' disabled={isDisabled} onClick={() => (previewRef.current = true)}>
                Salveaza si vizualizeaza
              </Button>

              <Button type='submit' disabled={isDisabled} onClick={() => (previewRef.current = false)}>
                Salveaza
              </Button>
            </>
          );
        }}
      >
        <div className='flex gap-4'>
          <DateField name='soldAt' label='Data' important />
          <SelectField name='soldToId' label='Cumparator' options={prerequisites!.buyers.all} important />
          <TextField name='comment' label='Comentariu' important />
        </div>
        <ButtonGroupField
          name='classification'
          label='Clasificare'
          options={[
            { label: 'Nimic', value: ProductTransactionDetailsClassification.Unknown },
            { label: 'Verde', value: ProductTransactionDetailsClassification.Green },
            { label: 'Galben', value: ProductTransactionDetailsClassification.Yellow },
            { label: 'Rosu', value: ProductTransactionDetailsClassification.Red },
          ]}
        />
        <DeliveredField />
        <ArrayField
          name='transactions'
          itemTemplate={(getName, remove, index) => (
            <div className='w-full mb-[-24px]'>
              <div className='grid grid-cols-7 gap-4'>
                <div className='col-span-3'>
                  <SelectField
                    name={getName('productDefinitionId')}
                    label='Nume'
                    options={prerequisites!.products.all}
                    hideLabel={!!index}
                  />
                </div>
                <div className='col-span-1'>
                  <NumericField name={getName('quantity')} label='Cantitate' hideLabel={!!index} />
                </div>
                <div className='col-span-1'>
                  <NumericField name={getName('price')} label='Pret' hideLabel={!!index} />
                </div>
                <div className='col-span-1'>
                  <TotalProductPrice getName={getName} hideLabel={!!index} />
                </div>
                <div className='flex mt-auto mb-6 h-11'>
                  <Button variant='red' onClick={remove}>
                    Sterge
                  </Button>
                </div>
              </div>
            </div>
          )}
          wrapperTemplate={(children, append) => (
            <div className='w-full'>
              {children}
              <div className='flex justify-start w-full'>
                <Button variant='green' type='button' onClick={() => append()}>
                  Adauga produs
                </Button>
              </div>
              <div className='grid grid-cols-7 gap-4'>
                <div className='col-span-5' />
                <div className='col-span-2'>
                  <TotalTransactionPrice />
                </div>
              </div>
            </div>
          )
          }
        />
      </FormContextProvider >
    </div >
  );
}
