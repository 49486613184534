import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { ErrorModal } from './graphql/ErrorModal';
import { GraphQlProvider } from './graphql/GraphQlProvider';
import './index.css';
import { ModalContextProvider } from './modal';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: 'always',
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <GraphQlProvider>
          <ModalContextProvider>
            <App />
            <ErrorModal />
          </ModalContextProvider>
        </GraphQlProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
