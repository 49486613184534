import { forwardRef } from 'react';
import { formatDate } from './DateHelpers';
import { DateOrDefault } from './DateOrDefault';

// @ts-ignore
export const DatePickerTrigger = forwardRef(({ onClick, date, important }, ref) => (
  <button
    onClick={onClick}
    //@ts-ignore
    ref={ref}
    type='button'
    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
      important ? 'bg-blue-100' : ''
    }`}
  >
    {formatDate((date as DateOrDefault).date)}
  </button>
));
