import { addMonths, addWeeks, endOfDay, endOfWeek, startOfDay, startOfWeek } from 'date-fns';

export class DateOrDefault {
  constructor(public date?: Date) {}

  public getOrDefault(defaultDate: Date) {
    const date = this.date || defaultDate;
    return date;
  }

  public addMonths(count: number) {
    if (!this.date) {
      return this;
    }

    return new DateOrDefault(addMonths(this.date, count));
  }

  public startOfDay() {
    return new DateOrDefault(this.date && startOfDay(this.date));
  }
  public endOfDay() {
    return new DateOrDefault(this.date && endOfDay(this.date));
  }
  public startOfWeek() {
    return new DateOrDefault(this.date && startOfWeek(this.date));
  }
  public endOfWeek() {
    return new DateOrDefault(this.date && endOfWeek(this.date));
  }

  public addWeeks(weeks: number) {
    return new DateOrDefault(this.date && addWeeks(this.date, weeks));
  }

  public static from(date?: Date | null) {
    if (date) {
      const currentDate = new Date();
      date.setHours(currentDate.getHours());
      date.setMinutes(currentDate.getMinutes());
    }
    return new DateOrDefault(date ?? undefined);
  }

  public static getToday = () => this.from(new Date());
}
