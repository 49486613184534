import {
  AdjustmentsIcon,
  BookOpenIcon,
  ChartBarIcon,
  CogIcon,
  CurrencyDollarIcon,
  PlusIcon,
  ShoppingCartIcon,
  UserCircleIcon,
  ClockIcon,
  IdentificationIcon,
} from '@heroicons/react/outline';
import { PropsWithChildren } from 'react';
import { NavBarItem } from './NavBarItem';

export interface NavBarProps { }

export function NavBar({ children }: PropsWithChildren<NavBarProps>) {
  return (
    <div className='w-full h-screen min-h-screen p-4 bg-gradient-to-r from-sky-400 to-blue-600'>
      <div className='h-full min-h-full rounded-md bg-gray-50/90'>
        <div className='flex flex-col h-full gap-2 align-top'>
          <div className='border-b border-gray-200'>
            <ul className='flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500'>
              <NavBarItem title='Nume' icon={BookOpenIcon} path='/knownNames' />
              <NavBarItem title={'Definitii materiale'} icon={UserCircleIcon} path='/material/definitions' />
              <NavBarItem title='Primire materiale' icon={ShoppingCartIcon} path='/material/transactions' />
              <NavBarItem title='Stoc materiale' icon={ChartBarIcon} path='/material/stock' />
              <NavBarItem title={'Norme producere'} icon={AdjustmentsIcon} path='/product/definitions' />
              <NavBarItem title={'Producere'} icon={PlusIcon} path='/product/production' />
              <NavBarItem title={'Vanzare'} icon={CurrencyDollarIcon} path='/product/transactions' />
              <NavBarItem title={'Stoc produse'} icon={ChartBarIcon} path='/product/stock' />
              <NavBarItem title={'Lucratori'} icon={IdentificationIcon} path='/workers' />
              <NavBarItem title={'Setari'} icon={CogIcon} path='/settings' />
              <NavBarItem title={'Istoric'} icon={ClockIcon} path='/logs' />
            </ul>
          </div>
          <div className='h-full px-8 py-3 overflow-y-auto'>{children}</div>
        </div>
      </div>
    </div>
  );
}
