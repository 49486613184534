import { ButtonGroupField, FormContextProvider, TextField } from 'src/form';
import { useModal } from 'src/modal';
import { KnownNameType } from 'src/types';
import { GetAllDocument, useGetOneQuery, useUpdateMutation } from './requests.generated';

export function UpdateForm({ id }: any) {
  const { close } = useModal();
  const [updateMutation] = useUpdateMutation();
  const { loading, data } = useGetOneQuery({ variables: { id } });

  return loading ? null : (
    <FormContextProvider
      defaultValues={data?.knowNames.one}
      onSubmit={(request: any) =>
        updateMutation({ variables: { request }, refetchQueries: [GetAllDocument], onCompleted: close })
      }
    >
      <TextField name='name' label='Nume' />
      <ButtonGroupField
        name='type'
        label='Tip'
        options={[
          { label: 'Vanzator', value: KnownNameType.Seller },
          { label: 'Cumparator', value: KnownNameType.Buyer },
          { label: 'Lucrator', value: KnownNameType.Worker },
        ]}
      />
    </FormContextProvider>
  );
}
