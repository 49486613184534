import React, { PropsWithChildren } from 'react';
import ReactModal from 'react-modal';
import { Box } from './Box';
import { Text } from './Text';

export interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  title?: string;
  header?: React.ReactElement;
  footer?: React.ReactElement;
  important?: boolean;
}

export function Modal({ isOpen, onClose, title, children, important }: PropsWithChildren<ModalProps>) {
  return (
    <ReactModal
      isOpen={isOpen}
      className='relative p-4'
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
      overlayClassName={`overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 ${
        important ? 'z-50' : 'z-40'
      } w-full md:inset-0 h-modal md:h-full flex items-center justify-center w-full h-full bg-gray-700 bg-opacity-80`}
    >
      <Box onClose={onClose}>
        <div className='px-6 py-6 lg:px-8 min-w-[480px] max-w-5xl overflow-y-auto max-h-[95vh]'>
          {!!title && <Text variant='title'>{title}</Text>}
          {!!children && <div className='space-y-6'>{children}</div>}
        </div>
      </Box>
    </ReactModal>
  );
}
