import ReactDiffViewer from 'react-diff-viewer-continued';
import { useGetLogsQuery } from './requests.generated';
import { LogEntry } from './LogEntry';

type Props = React.PropsWithChildren & {};

const a = {
  id: '17a5266f-3930-4978-b004-4d6e31bcbdbf',
  entityName: 'ProductProduction',
  timestamp: '2023-05-12T21:10:14.234Z',
  oldJson:
    '{\r\n  "ProductDefinitionId": "85973e7f-b833-4efd-b7b5-72950b105f82",\r\n  "CreatedAt": "2023-05-12T11:40:55.837Z",\r\n  "Quantity": 1133.0,\r\n  "Comment": "Igor",\r\n  "MaterialUsages": [\r\n    {\r\n      "Id": "00000000-0000-0000-0000-000000000000",\r\n      "MaterialDefinitionId": "10c5ab63-e306-4dc8-93aa-df1ab8cdd7fe",\r\n      "ProductProductionId": "eef392a9-2575-4740-87ec-8ea7a3cadd71",\r\n      "Quantity": 9240.0,\r\n      "Price": 0.0\r\n    },\r\n    {\r\n      "Id": "00000000-0000-0000-0000-000000000000",\r\n      "MaterialDefinitionId": "0fad4edb-202c-45f0-831b-258380d0cd8d",\r\n      "ProductProductionId": "eef392a9-2575-4740-87ec-8ea7a3cadd71",\r\n      "Quantity": 555.0,\r\n      "Price": 0.0\r\n    },\r\n    {\r\n      "Id": "00000000-0000-0000-0000-000000000000",\r\n      "MaterialDefinitionId": "323e9ea1-680a-4626-91fa-e8ba7c1c1d3d",\r\n      "ProductProductionId": "eef392a9-2575-4740-87ec-8ea7a3cadd71",\r\n      "Quantity": 113456.0,\r\n      "Price": 0.0\r\n    },\r\n    {\r\n      "Id": "00000000-0000-0000-0000-000000000000",\r\n      "MaterialDefinitionId": "6660b3b6-c117-4c74-8f82-f3ef2c1b85d0",\r\n      "ProductProductionId": "eef392a9-2575-4740-87ec-8ea7a3cadd71",\r\n      "Quantity": 49.5,\r\n      "Price": 0.0\r\n    }\r\n  ],\r\n  "Id": "eef392a9-2575-4740-87ec-8ea7a3cadd71"\r\n}',
  newJson:
    '{\r\n  "ProductDefinitionId": "85973e7f-b833-4efd-b7b5-72950b105f82",\r\n  "CreatedAt": "2023-05-12T11:40:55.837Z",\r\n  "Quantity": 1133.0,\r\n  "Comment": "Igor",\r\n  "MaterialUsages": [\r\n    {\r\n      "Id": "98a9bcca-277d-45d0-aab5-ff639ec3ef2c",\r\n      "MaterialDefinitionId": "10c5ab63-e306-4dc8-93aa-df1ab8cdd7fe",\r\n      "ProductProductionId": "eef392a9-2575-4740-87ec-8ea7a3cadd71",\r\n      "Quantity": 9240.0,\r\n      "Price": 0.0\r\n    },\r\n    {\r\n      "Id": "17575bec-86db-4200-96bd-45339161d4d4",\r\n      "MaterialDefinitionId": "0fad4edb-202c-45f0-831b-258380d0cd8d",\r\n      "ProductProductionId": "eef392a9-2575-4740-87ec-8ea7a3cadd71",\r\n      "Quantity": 555.0,\r\n      "Price": 0.0\r\n    },\r\n    {\r\n      "Id": "f504dabb-4d18-49b9-9dc8-51721d27c00f",\r\n      "MaterialDefinitionId": "323e9ea1-680a-4626-91fa-e8ba7c1c1d3d",\r\n      "ProductProductionId": "eef392a9-2575-4740-87ec-8ea7a3cadd71",\r\n      "Quantity": 113456.0,\r\n      "Price": 0.0\r\n    },\r\n    {\r\n      "Id": "976e574b-988e-4d74-a2f0-1c9303acaf19",\r\n      "MaterialDefinitionId": "6660b3b6-c117-4c74-8f82-f3ef2c1b85d0",\r\n      "ProductProductionId": "eef392a9-2575-4740-87ec-8ea7a3cadd71",\r\n      "Quantity": 49.5,\r\n      "Price": 0.0\r\n    }\r\n  ],\r\n  "Id": "eef392a9-2575-4740-87ec-8ea7a3cadd71"\r\n}',
};

export function LogsPage({}: Props) {
  const { data, loading } = useGetLogsQuery();

  return loading ? null : (
    <div className='grid grid-cols-3'>
      <div className='text-lg font-bold'>Operatie</div>
      <div className='text-lg font-bold'>Nume entitate</div>
      <div className='text-lg font-bold'>Data</div>
      {data?.changeLogEntries!.map((log) => (
        <LogEntry key={log.id} log={log} />
      ))}
    </div>
  );
}
