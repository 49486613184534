import { DateRangePicker, Table, TableHeaders } from 'src/components';
import { useModal } from 'src/modal';
import { UpdateForm } from './UpdateForm';
import { AddForm } from './AddForm';
import { MaterialUsages } from './MaterialUsages';
import { GetAllDocument, GetAllQuery, useDeleteByIdMutation, useGetAllQuery } from './requests.generated';
import { DateRangeOrDefault } from 'src/components/DatePicker/DateRangeOrDefault';
import { useDateRange } from 'src/hooks/useDateRange';
import { ProductProductionLine } from 'src/types';

const productionLineMapping = {
  [ProductProductionLine.None]: "Nedefinit",
  [ProductProductionLine.First]: "Linia 1",
  [ProductProductionLine.Second]: "Linia 2"
}

export function ProductProductionPage() {
  const headers: TableHeaders<GetAllQuery['productProductions']['all'][0]> = [
    { renderCell: (row) => row.createdAt, title: 'Data creare' },
    { renderCell: (row) => row.name, title: 'Nume produs' },
    { renderCell: (row) => row.quantity, title: 'Cantitate' },
    { renderCell: (row) => <MaterialUsages usages={row.usages} />, title: 'Materiale utilizate' },
    { renderCell: (row) => row.comment, title: 'Comentariu' },
    { renderCell: (row) => productionLineMapping[row.productionLine], title: 'Linia de producere' },
    {
      renderCell: (row) => <div className='flex flex-col gap-2'>{row.workers.map(w => <div>{w}</div>)}
      </div>, title: 'Lucratori'
    },
  ];

  const { show } = useModal();

  const [variables, interval, setRange] = useDateRange(DateRangeOrDefault.Default);

  const { data } = useGetAllQuery({ variables });
  const [deleteMutation] = useDeleteByIdMutation();
  return (
    <div className='flex flex-col gap-3'>
      <div>
        <AddForm />
      </div>
      <DateRangePicker label={'Interval'} onChange={setRange} interval={interval} />
      <Table
        data={data?.productProductions.all}
        headers={headers}
        onEdit={(item) => show(<UpdateForm id={item.id} />)}
        onDelete={(item) => deleteMutation({ variables: { id: item.id }, refetchQueries: [GetAllDocument] })}
      />
    </div>
  );
}
