import { Box, BoxContainer, Button } from "src/components";
import { ArrayField, NumericField, SelectField, useFormContext } from "src/form";
import { GetPrerequisitesQuery } from "./requests.generated";
import { ProductProductionLine } from "src/types";

type Props = React.PropsWithChildren & { prerequisites: GetPrerequisitesQuery };

export function RequirementsForm({ prerequisites }: Props) {
    const { watch, getValues } = useFormContext();

    const productionLine = watch("productionLine") as ProductProductionLine;


    return <ArrayField
        name='requirements'
        itemTemplate={(getName, remove) => (
            <Box onClose={remove} hidden={getValues(getName("productionLine") as ProductProductionLine) !== productionLine}>
                <SelectField
                    name={getName('materialDefinitionId')}
                    label={'Material'}
                    options={prerequisites.materials.all}
                />
                <NumericField name={getName('quantity')} label={'Cantitate'} />
            </Box>
        )}
        wrapperTemplate={(children, append) => (
            <BoxContainer>
                {children}
                <div className='flex justify-start w-full my-4'>
                    <Button variant='green' type='button' onClick={() => append({ productionLine })}>
                        Adauga material
                    </Button>
                </div>
            </BoxContainer>
        )}
    />;
}
