import { PropsWithChildren } from 'react';
import { Button, ButtonProps } from './Button';
import { ConfirmationPopup, ConfirmationPopupProps } from './ConfirmationPopup';
import { IconButton, IconButtonProps } from './IconButton';

export interface ConfirmationButtonProps {
  confirmationPopupProps: Omit<ConfirmationPopupProps, 'onConfirm'>;
  buttonProps: ButtonProps;
}

export function ConfirmationButton({
  buttonProps: { onClick, ...buttonProps },
  confirmationPopupProps,
}: PropsWithChildren<ConfirmationButtonProps>) {
  return (
    <ConfirmationPopup {...confirmationPopupProps} onConfirm={() => onClick && onClick(null!)}>
      <div className='inline'>
        <Button {...(buttonProps as any)} />
      </div>
    </ConfirmationPopup>
  );
}

export interface ConfirmationIconButtonProps {
  confirmationPopupProps: Omit<ConfirmationPopupProps, 'onConfirm'>;
  buttonProps: IconButtonProps;
}

export function ConfirmationIconButton({
  buttonProps: { onClick, ...buttonProps },
  confirmationPopupProps,
}: PropsWithChildren<ConfirmationIconButtonProps>) {
  return (
    <ConfirmationPopup {...confirmationPopupProps} onConfirm={() => onClick && onClick(null!)}>
      <div className='inline'>
        <IconButton {...buttonProps} />
      </div>
    </ConfirmationPopup>
  );
}
