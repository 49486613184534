import { createContext, PropsWithChildren, ReactNode, useContext } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { Button } from 'src/components';

type FormContextType = UseFormReturn<any, object> & {};

const FormContext = createContext<FormContextType | null>(null);

type FormContextProviderProps<T> = {
  onSubmit: (data: T) => void;
  defaultValues?: T;
  submitButtonTemplate?: (isDisabled: boolean) => ReactNode;
  renderAfter?: ReactNode;
};

export function FormContextProvider<T = any>({
  onSubmit,
  children,
  submitButtonTemplate,
  defaultValues,
  renderAfter,
}: PropsWithChildren<FormContextProviderProps<T>>) {
  const methods = useForm<any>({
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues,
  });
  const { handleSubmit, formState } = methods;
  const { isSubmitting } = formState;
  const isSubmitDisabled = isSubmitting;
  return (
    <FormContext.Provider value={{ ...methods }}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete='off' spellCheck={false}>
        {children}
        <div className='flex justify-end'>
          {submitButtonTemplate ? (
            submitButtonTemplate(isSubmitDisabled)
          ) : (
            <Button type='submit' disabled={isSubmitDisabled}>
              Salveaza
            </Button>
          )}
        </div>
      </form>
      {renderAfter}
    </FormContext.Provider>
  );
}

export function useFormContext() {
  return useContext(FormContext) as FormContextType;
}
