export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  UUID: any;
};

export type AddKnownNameRequestInput = {
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<KnownNameType>;
};

export type AddMaterialDefinitionRequestInput = {
  name?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
};

export type AddMaterialTransactionRequestInput = {
  materialDefinitionId?: InputMaybe<Scalars['UUID']>;
  quantity?: InputMaybe<Scalars['Float']>;
  receivedAt?: InputMaybe<Scalars['DateTime']>;
  receivedFromId?: InputMaybe<Scalars['UUID']>;
};

export type AddProductDefinitionRequestInput = {
  category: ProductCategory;
  name?: InputMaybe<Scalars['String']>;
  requirements?: InputMaybe<Array<AddProductDefinitionRequirementRequestInput>>;
  unit?: InputMaybe<Scalars['String']>;
};

export type AddProductDefinitionRequirementRequestInput = {
  materialDefinitionId?: InputMaybe<Scalars['UUID']>;
  productionLine: ProductProductionLine;
  quantity?: InputMaybe<Scalars['Float']>;
};

export type AddProductProductionRequestInput = {
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  productDefinitionId?: InputMaybe<Scalars['UUID']>;
  productionLine: ProductProductionLine;
  quantity?: InputMaybe<Scalars['Float']>;
  usages?: InputMaybe<Array<AddProductProductionRequirementRequestInput>>;
  workerIds: Array<Scalars['UUID']>;
};

export type AddProductProductionRequirementRequestInput = {
  materialDefinitionId?: InputMaybe<Scalars['UUID']>;
  quantity?: InputMaybe<Scalars['Float']>;
};

export type AddProductTransactionItemRequestInput = {
  price?: InputMaybe<Scalars['Float']>;
  productDefinitionId?: InputMaybe<Scalars['UUID']>;
  quantity?: InputMaybe<Scalars['Float']>;
};

export type AddProductTransactionRequestInput = {
  classification?: InputMaybe<ProductTransactionDetailsClassification>;
  comment?: InputMaybe<Scalars['String']>;
  deliveryState: DeliveryState;
  soldAt?: InputMaybe<Scalars['DateTime']>;
  soldToId?: InputMaybe<Scalars['UUID']>;
  transactions?: InputMaybe<Array<AddProductTransactionItemRequestInput>>;
};

export type ChangeLogEntry = {
  __typename?: 'ChangeLogEntry';
  entityName: Scalars['String'];
  id: Scalars['UUID'];
  newJson?: Maybe<Scalars['String']>;
  oldJson?: Maybe<Scalars['String']>;
  timestamp: Scalars['DateTime'];
};

export type ChangeLogEntryFilterInput = {
  and?: InputMaybe<Array<ChangeLogEntryFilterInput>>;
  entityName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  newJson?: InputMaybe<StringOperationFilterInput>;
  oldJson?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ChangeLogEntryFilterInput>>;
  timestamp?: InputMaybe<DateTimeOperationFilterInput>;
};

export type ChangeLogEntrySortInput = {
  entityName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  newJson?: InputMaybe<SortEnumType>;
  oldJson?: InputMaybe<SortEnumType>;
  timestamp?: InputMaybe<SortEnumType>;
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export enum DeliveryState {
  Delivered = 'DELIVERED',
  NotDelivered = 'NOT_DELIVERED',
  Unknown = 'UNKNOWN'
}

export type DeliveryStateOperationFilterInput = {
  eq?: InputMaybe<DeliveryState>;
  in?: InputMaybe<Array<DeliveryState>>;
  neq?: InputMaybe<DeliveryState>;
  nin?: InputMaybe<Array<DeliveryState>>;
};

export type FloatOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  ngt?: InputMaybe<Scalars['Float']>;
  ngte?: InputMaybe<Scalars['Float']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  nlt?: InputMaybe<Scalars['Float']>;
  nlte?: InputMaybe<Scalars['Float']>;
};

export type GraphQlMutations = {
  __typename?: 'GraphQlMutations';
  knowNames: KnownNameMutations;
  materialDefinitions: MaterialDefinitionMutations;
  materialTransactions: MaterialTransactionMutations;
  productDefinitions: ProductDefinitionMutations;
  productProductions: ProductProductionMutations;
  productTransactions: ProductTransactionMutations;
};

export type GraphQlQueries = {
  __typename?: 'GraphQlQueries';
  changeLogEntries: Array<ChangeLogEntry>;
  knowNames: KnownNameQueries;
  materialDefinitions: MaterialDefinitionQueries;
  materialTransactions: MaterialTransactionQueries;
  productDefinitions: ProductDefinitionQueries;
  productProductions: ProductProductionQueries;
  productTransactions: ProductTransactionQueries;
  stocks: StockQueries;
  workerResults: WorkerQueries;
};


export type GraphQlQueriesChangeLogEntriesArgs = {
  order?: InputMaybe<Array<ChangeLogEntrySortInput>>;
  where?: InputMaybe<ChangeLogEntryFilterInput>;
};

export type KnownName = {
  __typename?: 'KnownName';
  id: Scalars['UUID'];
  name?: Maybe<Scalars['String']>;
  type: KnownNameType;
};

export type KnownNameFilterInput = {
  and?: InputMaybe<Array<KnownNameFilterInput>>;
  id?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<KnownNameFilterInput>>;
  type?: InputMaybe<KnownNameTypeOperationFilterInput>;
};

export type KnownNameMutations = {
  __typename?: 'KnownNameMutations';
  add: Scalars['UUID'];
  delete: Scalars['Boolean'];
  update: Scalars['Boolean'];
};


export type KnownNameMutationsAddArgs = {
  addRequest: AddKnownNameRequestInput;
};


export type KnownNameMutationsDeleteArgs = {
  id: Scalars['UUID'];
};


export type KnownNameMutationsUpdateArgs = {
  updateRequest: UpdateKnownNameRequestInput;
};

export type KnownNameQueries = {
  __typename?: 'KnownNameQueries';
  all: Array<KnownName>;
  one: KnownName;
};


export type KnownNameQueriesAllArgs = {
  order?: InputMaybe<Array<KnownNameSortInput>>;
  where?: InputMaybe<KnownNameFilterInput>;
};


export type KnownNameQueriesOneArgs = {
  id: Scalars['UUID'];
};

export type KnownNameSortInput = {
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
};

export enum KnownNameType {
  Buyer = 'BUYER',
  Seller = 'SELLER',
  Worker = 'WORKER'
}

export type KnownNameTypeOperationFilterInput = {
  eq?: InputMaybe<KnownNameType>;
  in?: InputMaybe<Array<KnownNameType>>;
  neq?: InputMaybe<KnownNameType>;
  nin?: InputMaybe<Array<KnownNameType>>;
};

export type ListFilterInputTypeOfMaterialTransactionFilterInput = {
  all?: InputMaybe<MaterialTransactionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MaterialTransactionFilterInput>;
  some?: InputMaybe<MaterialTransactionFilterInput>;
};

export type ListFilterInputTypeOfMaterialUsageFilterInput = {
  all?: InputMaybe<MaterialUsageFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MaterialUsageFilterInput>;
  some?: InputMaybe<MaterialUsageFilterInput>;
};

export type ListFilterInputTypeOfProductDefinitionRequirementDtoFilterInput = {
  all?: InputMaybe<ProductDefinitionRequirementDtoFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductDefinitionRequirementDtoFilterInput>;
  some?: InputMaybe<ProductDefinitionRequirementDtoFilterInput>;
};

export type ListFilterInputTypeOfProductProductionUsageDtoFilterInput = {
  all?: InputMaybe<ProductProductionUsageDtoFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductProductionUsageDtoFilterInput>;
  some?: InputMaybe<ProductProductionUsageDtoFilterInput>;
};

export type ListFilterInputTypeOfProductRequirementFilterInput = {
  all?: InputMaybe<ProductRequirementFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductRequirementFilterInput>;
  some?: InputMaybe<ProductRequirementFilterInput>;
};

export type ListFilterInputTypeOfProductTransactionItemDtoFilterInput = {
  all?: InputMaybe<ProductTransactionItemDtoFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductTransactionItemDtoFilterInput>;
  some?: InputMaybe<ProductTransactionItemDtoFilterInput>;
};

export type ListFilterInputTypeOfWorkerProductionFilterInput = {
  all?: InputMaybe<WorkerProductionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<WorkerProductionFilterInput>;
  some?: InputMaybe<WorkerProductionFilterInput>;
};

export type ListStringOperationFilterInput = {
  all?: InputMaybe<StringOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<StringOperationFilterInput>;
  some?: InputMaybe<StringOperationFilterInput>;
};

export type ListUuidOperationFilterInput = {
  all?: InputMaybe<UuidOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<UuidOperationFilterInput>;
  some?: InputMaybe<UuidOperationFilterInput>;
};

export type MaterialDefinition = {
  __typename?: 'MaterialDefinition';
  id: Scalars['UUID'];
  materialTransactions?: Maybe<Array<Maybe<MaterialTransaction>>>;
  materialUsages?: Maybe<Array<Maybe<MaterialUsage>>>;
  name?: Maybe<Scalars['String']>;
  productRequirements?: Maybe<Array<Maybe<ProductRequirement>>>;
  unit?: Maybe<Scalars['String']>;
};

export type MaterialDefinitionFilterInput = {
  and?: InputMaybe<Array<MaterialDefinitionFilterInput>>;
  id?: InputMaybe<UuidOperationFilterInput>;
  materialTransactions?: InputMaybe<ListFilterInputTypeOfMaterialTransactionFilterInput>;
  materialUsages?: InputMaybe<ListFilterInputTypeOfMaterialUsageFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<MaterialDefinitionFilterInput>>;
  productRequirements?: InputMaybe<ListFilterInputTypeOfProductRequirementFilterInput>;
  unit?: InputMaybe<StringOperationFilterInput>;
};

export type MaterialDefinitionMutations = {
  __typename?: 'MaterialDefinitionMutations';
  add: Scalars['UUID'];
  delete: Scalars['Boolean'];
  update: Scalars['Boolean'];
};


export type MaterialDefinitionMutationsAddArgs = {
  addRequest: AddMaterialDefinitionRequestInput;
};


export type MaterialDefinitionMutationsDeleteArgs = {
  id: Scalars['UUID'];
};


export type MaterialDefinitionMutationsUpdateArgs = {
  updateRequest: UpdateMaterialDefinitionRequestInput;
};

export type MaterialDefinitionQueries = {
  __typename?: 'MaterialDefinitionQueries';
  all: Array<MaterialDefinition>;
  one: MaterialDefinition;
};


export type MaterialDefinitionQueriesAllArgs = {
  order?: InputMaybe<Array<MaterialDefinitionSortInput>>;
  where?: InputMaybe<MaterialDefinitionFilterInput>;
};


export type MaterialDefinitionQueriesOneArgs = {
  id: Scalars['UUID'];
};

export type MaterialDefinitionSortInput = {
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  unit?: InputMaybe<SortEnumType>;
};

export type MaterialStock = {
  __typename?: 'MaterialStock';
  bought: Scalars['String'];
  finalStock: Scalars['String'];
  initialStock: Scalars['String'];
  materialName: Scalars['String'];
  used: Scalars['String'];
};

export type MaterialTransaction = {
  __typename?: 'MaterialTransaction';
  comment?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  materialDefinition?: Maybe<MaterialDefinition>;
  materialDefinitionId: Scalars['UUID'];
  /** @deprecated Present in the database, but not used */
  price: Scalars['Float'];
  quantity: Scalars['Float'];
  receivedAt: Scalars['DateTime'];
  receivedFrom?: Maybe<KnownName>;
  receivedFromId: Scalars['UUID'];
};

export type MaterialTransactionDto = {
  __typename?: 'MaterialTransactionDto';
  comment?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  materialDefinitionId: Scalars['UUID'];
  name: Scalars['String'];
  quantity: Scalars['String'];
  receivedAt: Scalars['String'];
  receivedAtDateTime: Scalars['DateTime'];
  receivedFrom: Scalars['String'];
  receivedFromId: Scalars['UUID'];
};

export type MaterialTransactionDtoFilterInput = {
  and?: InputMaybe<Array<MaterialTransactionDtoFilterInput>>;
  comment?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  materialDefinitionId?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<MaterialTransactionDtoFilterInput>>;
  quantity?: InputMaybe<StringOperationFilterInput>;
  receivedAt?: InputMaybe<StringOperationFilterInput>;
  receivedAtDateTime?: InputMaybe<DateTimeOperationFilterInput>;
  receivedFrom?: InputMaybe<StringOperationFilterInput>;
  receivedFromId?: InputMaybe<UuidOperationFilterInput>;
};

export type MaterialTransactionDtoSortInput = {
  comment?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  materialDefinitionId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  quantity?: InputMaybe<SortEnumType>;
  receivedAt?: InputMaybe<SortEnumType>;
  receivedAtDateTime?: InputMaybe<SortEnumType>;
  receivedFrom?: InputMaybe<SortEnumType>;
  receivedFromId?: InputMaybe<SortEnumType>;
};

export type MaterialTransactionFilterInput = {
  and?: InputMaybe<Array<MaterialTransactionFilterInput>>;
  comment?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  materialDefinition?: InputMaybe<MaterialDefinitionFilterInput>;
  materialDefinitionId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<MaterialTransactionFilterInput>>;
  price?: InputMaybe<FloatOperationFilterInput>;
  quantity?: InputMaybe<FloatOperationFilterInput>;
  receivedAt?: InputMaybe<DateTimeOperationFilterInput>;
  receivedFrom?: InputMaybe<KnownNameFilterInput>;
  receivedFromId?: InputMaybe<UuidOperationFilterInput>;
};

export type MaterialTransactionMutations = {
  __typename?: 'MaterialTransactionMutations';
  add: Scalars['UUID'];
  delete: Scalars['Boolean'];
  update: Scalars['Boolean'];
};


export type MaterialTransactionMutationsAddArgs = {
  addRequest: AddMaterialTransactionRequestInput;
};


export type MaterialTransactionMutationsDeleteArgs = {
  id: Scalars['UUID'];
};


export type MaterialTransactionMutationsUpdateArgs = {
  updateRequest: UpdateMaterialTransactionRequestInput;
};

export type MaterialTransactionQueries = {
  __typename?: 'MaterialTransactionQueries';
  all: Array<MaterialTransactionDto>;
  one: MaterialTransactionDto;
};


export type MaterialTransactionQueriesAllArgs = {
  order?: InputMaybe<Array<MaterialTransactionDtoSortInput>>;
  where?: InputMaybe<MaterialTransactionDtoFilterInput>;
};


export type MaterialTransactionQueriesOneArgs = {
  id: Scalars['UUID'];
};

export type MaterialUsage = {
  __typename?: 'MaterialUsage';
  id: Scalars['UUID'];
  materialDefinition?: Maybe<MaterialDefinition>;
  materialDefinitionId: Scalars['UUID'];
  /** @deprecated Present in the database, but not used */
  price: Scalars['Float'];
  productProduction?: Maybe<ProductProduction>;
  productProductionId: Scalars['UUID'];
  quantity: Scalars['Float'];
};

export type MaterialUsageFilterInput = {
  and?: InputMaybe<Array<MaterialUsageFilterInput>>;
  id?: InputMaybe<UuidOperationFilterInput>;
  materialDefinition?: InputMaybe<MaterialDefinitionFilterInput>;
  materialDefinitionId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<MaterialUsageFilterInput>>;
  price?: InputMaybe<FloatOperationFilterInput>;
  productProduction?: InputMaybe<ProductProductionFilterInput>;
  productProductionId?: InputMaybe<UuidOperationFilterInput>;
  quantity?: InputMaybe<FloatOperationFilterInput>;
};

export enum ProductCategory {
  Adezivi = 'ADEZIVI',
  Altele = 'ALTELE',
  Grunduri = 'GRUNDURI',
  Total = 'TOTAL',
  Unknown = 'UNKNOWN',
  Vopsele = 'VOPSELE'
}

export type ProductCategoryOperationFilterInput = {
  eq?: InputMaybe<ProductCategory>;
  in?: InputMaybe<Array<ProductCategory>>;
  neq?: InputMaybe<ProductCategory>;
  nin?: InputMaybe<Array<ProductCategory>>;
};

export type ProductDefinition = {
  __typename?: 'ProductDefinition';
  category: ProductCategory;
  id: Scalars['UUID'];
  name?: Maybe<Scalars['String']>;
  productRequirements?: Maybe<Array<Maybe<ProductRequirement>>>;
  unit?: Maybe<Scalars['String']>;
};

export type ProductDefinitionDto = {
  __typename?: 'ProductDefinitionDto';
  category: ProductCategory;
  id: Scalars['UUID'];
  name: Scalars['String'];
  requirements: Array<ProductDefinitionRequirementDto>;
  unit: Scalars['String'];
};

export type ProductDefinitionDtoFilterInput = {
  and?: InputMaybe<Array<ProductDefinitionDtoFilterInput>>;
  category?: InputMaybe<ProductCategoryOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProductDefinitionDtoFilterInput>>;
  requirements?: InputMaybe<ListFilterInputTypeOfProductDefinitionRequirementDtoFilterInput>;
  unit?: InputMaybe<StringOperationFilterInput>;
};

export type ProductDefinitionDtoSortInput = {
  category?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  unit?: InputMaybe<SortEnumType>;
};

export type ProductDefinitionFilterInput = {
  and?: InputMaybe<Array<ProductDefinitionFilterInput>>;
  category?: InputMaybe<ProductCategoryOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProductDefinitionFilterInput>>;
  productRequirements?: InputMaybe<ListFilterInputTypeOfProductRequirementFilterInput>;
  unit?: InputMaybe<StringOperationFilterInput>;
};

export type ProductDefinitionMutations = {
  __typename?: 'ProductDefinitionMutations';
  add: Scalars['UUID'];
  delete: Scalars['Boolean'];
  update: Scalars['Boolean'];
};


export type ProductDefinitionMutationsAddArgs = {
  addRequest: AddProductDefinitionRequestInput;
};


export type ProductDefinitionMutationsDeleteArgs = {
  id: Scalars['UUID'];
};


export type ProductDefinitionMutationsUpdateArgs = {
  updateRequest: UpdateProductDefinitionRequestInput;
};

export type ProductDefinitionQueries = {
  __typename?: 'ProductDefinitionQueries';
  all: Array<ProductDefinitionDto>;
  one: ProductDefinitionDto;
};


export type ProductDefinitionQueriesAllArgs = {
  order?: InputMaybe<Array<ProductDefinitionDtoSortInput>>;
  where?: InputMaybe<ProductDefinitionDtoFilterInput>;
};


export type ProductDefinitionQueriesOneArgs = {
  id: Scalars['UUID'];
};

export type ProductDefinitionRequirementDto = {
  __typename?: 'ProductDefinitionRequirementDto';
  displayQuantity: Scalars['String'];
  materialDefinitionId: Scalars['UUID'];
  name: Scalars['String'];
  productionLine: ProductProductionLine;
  quantity: Scalars['Float'];
};

export type ProductDefinitionRequirementDtoFilterInput = {
  and?: InputMaybe<Array<ProductDefinitionRequirementDtoFilterInput>>;
  displayQuantity?: InputMaybe<StringOperationFilterInput>;
  materialDefinitionId?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProductDefinitionRequirementDtoFilterInput>>;
  productionLine?: InputMaybe<ProductProductionLineOperationFilterInput>;
  quantity?: InputMaybe<FloatOperationFilterInput>;
};

export type ProductProduction = {
  __typename?: 'ProductProduction';
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  materialUsages?: Maybe<Array<Maybe<MaterialUsage>>>;
  productDefinition?: Maybe<ProductDefinition>;
  productDefinitionId: Scalars['UUID'];
  productionLine: ProductProductionLine;
  quantity: Scalars['Float'];
  workerProductions?: Maybe<Array<Maybe<WorkerProduction>>>;
};

export type ProductProductionDto = {
  __typename?: 'ProductProductionDto';
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdAtDateTime: Scalars['DateTime'];
  id: Scalars['UUID'];
  name: Scalars['String'];
  productDefinitionId: Scalars['UUID'];
  productionLine: ProductProductionLine;
  quantity: Scalars['String'];
  quantityDouble: Scalars['Float'];
  usages: Array<ProductProductionUsageDto>;
  workerIds: Array<Scalars['UUID']>;
  workers: Array<Scalars['String']>;
};

export type ProductProductionDtoFilterInput = {
  and?: InputMaybe<Array<ProductProductionDtoFilterInput>>;
  comment?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<StringOperationFilterInput>;
  createdAtDateTime?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProductProductionDtoFilterInput>>;
  productDefinitionId?: InputMaybe<UuidOperationFilterInput>;
  productionLine?: InputMaybe<ProductProductionLineOperationFilterInput>;
  quantity?: InputMaybe<StringOperationFilterInput>;
  quantityDouble?: InputMaybe<FloatOperationFilterInput>;
  usages?: InputMaybe<ListFilterInputTypeOfProductProductionUsageDtoFilterInput>;
  workerIds?: InputMaybe<ListUuidOperationFilterInput>;
  workers?: InputMaybe<ListStringOperationFilterInput>;
};

export type ProductProductionDtoSortInput = {
  comment?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdAtDateTime?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  productDefinitionId?: InputMaybe<SortEnumType>;
  productionLine?: InputMaybe<SortEnumType>;
  quantity?: InputMaybe<SortEnumType>;
  quantityDouble?: InputMaybe<SortEnumType>;
};

export type ProductProductionFilterInput = {
  and?: InputMaybe<Array<ProductProductionFilterInput>>;
  comment?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  materialUsages?: InputMaybe<ListFilterInputTypeOfMaterialUsageFilterInput>;
  or?: InputMaybe<Array<ProductProductionFilterInput>>;
  productDefinition?: InputMaybe<ProductDefinitionFilterInput>;
  productDefinitionId?: InputMaybe<UuidOperationFilterInput>;
  productionLine?: InputMaybe<ProductProductionLineOperationFilterInput>;
  quantity?: InputMaybe<FloatOperationFilterInput>;
  workerProductions?: InputMaybe<ListFilterInputTypeOfWorkerProductionFilterInput>;
};

export enum ProductProductionLine {
  First = 'FIRST',
  None = 'NONE',
  Second = 'SECOND'
}

export type ProductProductionLineOperationFilterInput = {
  eq?: InputMaybe<ProductProductionLine>;
  in?: InputMaybe<Array<ProductProductionLine>>;
  neq?: InputMaybe<ProductProductionLine>;
  nin?: InputMaybe<Array<ProductProductionLine>>;
};

export type ProductProductionMutations = {
  __typename?: 'ProductProductionMutations';
  add: Scalars['UUID'];
  delete: Scalars['Boolean'];
  update: Scalars['Boolean'];
};


export type ProductProductionMutationsAddArgs = {
  addRequest: AddProductProductionRequestInput;
};


export type ProductProductionMutationsDeleteArgs = {
  id: Scalars['UUID'];
};


export type ProductProductionMutationsUpdateArgs = {
  updateRequest: UpdateProductProductionRequestInput;
};

export type ProductProductionQueries = {
  __typename?: 'ProductProductionQueries';
  all: Array<ProductProductionDto>;
  one: ProductProductionDto;
};


export type ProductProductionQueriesAllArgs = {
  order?: InputMaybe<Array<ProductProductionDtoSortInput>>;
  where?: InputMaybe<ProductProductionDtoFilterInput>;
};


export type ProductProductionQueriesOneArgs = {
  id: Scalars['UUID'];
};

export type ProductProductionUsageDto = {
  __typename?: 'ProductProductionUsageDto';
  materialDefinitionId: Scalars['UUID'];
  name: Scalars['String'];
  quantity: Scalars['String'];
  quantityDouble: Scalars['Float'];
};

export type ProductProductionUsageDtoFilterInput = {
  and?: InputMaybe<Array<ProductProductionUsageDtoFilterInput>>;
  materialDefinitionId?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProductProductionUsageDtoFilterInput>>;
  quantity?: InputMaybe<StringOperationFilterInput>;
  quantityDouble?: InputMaybe<FloatOperationFilterInput>;
};

export type ProductRequirement = {
  __typename?: 'ProductRequirement';
  materialDefinition?: Maybe<MaterialDefinition>;
  materialDefinitionId: Scalars['UUID'];
  productDefinition?: Maybe<ProductDefinition>;
  productDefinitionId: Scalars['UUID'];
  productionLine: ProductProductionLine;
  quantity: Scalars['Float'];
};

export type ProductRequirementFilterInput = {
  and?: InputMaybe<Array<ProductRequirementFilterInput>>;
  materialDefinition?: InputMaybe<MaterialDefinitionFilterInput>;
  materialDefinitionId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<ProductRequirementFilterInput>>;
  productDefinition?: InputMaybe<ProductDefinitionFilterInput>;
  productDefinitionId?: InputMaybe<UuidOperationFilterInput>;
  productionLine?: InputMaybe<ProductProductionLineOperationFilterInput>;
  quantity?: InputMaybe<FloatOperationFilterInput>;
};

export type ProductStock = {
  __typename?: 'ProductStock';
  category: ProductCategory;
  finalStock: Scalars['String'];
  initialStock: Scalars['String'];
  produced: Scalars['String'];
  productName: Scalars['String'];
  sold: Scalars['String'];
};

export enum ProductTransactionDetailsClassification {
  Green = 'GREEN',
  Red = 'RED',
  Unknown = 'UNKNOWN',
  Yellow = 'YELLOW'
}

export type ProductTransactionDetailsClassificationOperationFilterInput = {
  eq?: InputMaybe<ProductTransactionDetailsClassification>;
  in?: InputMaybe<Array<ProductTransactionDetailsClassification>>;
  neq?: InputMaybe<ProductTransactionDetailsClassification>;
  nin?: InputMaybe<Array<ProductTransactionDetailsClassification>>;
};

export type ProductTransactionDto = {
  __typename?: 'ProductTransactionDto';
  classification: ProductTransactionDetailsClassification;
  comment: Scalars['String'];
  deliveryState: DeliveryState;
  id: Scalars['UUID'];
  soldAt: Scalars['String'];
  soldAtDateTime: Scalars['DateTime'];
  soldTo: Scalars['String'];
  soldToId: Scalars['UUID'];
  totalPrice: Scalars['String'];
  transactions: Array<ProductTransactionItemDto>;
};

export type ProductTransactionDtoFilterInput = {
  and?: InputMaybe<Array<ProductTransactionDtoFilterInput>>;
  classification?: InputMaybe<ProductTransactionDetailsClassificationOperationFilterInput>;
  comment?: InputMaybe<StringOperationFilterInput>;
  deliveryState?: InputMaybe<DeliveryStateOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<ProductTransactionDtoFilterInput>>;
  soldAt?: InputMaybe<StringOperationFilterInput>;
  soldAtDateTime?: InputMaybe<DateTimeOperationFilterInput>;
  soldTo?: InputMaybe<StringOperationFilterInput>;
  soldToId?: InputMaybe<UuidOperationFilterInput>;
  totalPrice?: InputMaybe<StringOperationFilterInput>;
  transactions?: InputMaybe<ListFilterInputTypeOfProductTransactionItemDtoFilterInput>;
};

export type ProductTransactionDtoSortInput = {
  classification?: InputMaybe<SortEnumType>;
  comment?: InputMaybe<SortEnumType>;
  deliveryState?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  soldAt?: InputMaybe<SortEnumType>;
  soldAtDateTime?: InputMaybe<SortEnumType>;
  soldTo?: InputMaybe<SortEnumType>;
  soldToId?: InputMaybe<SortEnumType>;
  totalPrice?: InputMaybe<SortEnumType>;
};

export type ProductTransactionItemDto = {
  __typename?: 'ProductTransactionItemDto';
  price: Scalars['Float'];
  productDefinitionId: Scalars['UUID'];
  quantity: Scalars['Float'];
};

export type ProductTransactionItemDtoFilterInput = {
  and?: InputMaybe<Array<ProductTransactionItemDtoFilterInput>>;
  or?: InputMaybe<Array<ProductTransactionItemDtoFilterInput>>;
  price?: InputMaybe<FloatOperationFilterInput>;
  productDefinitionId?: InputMaybe<UuidOperationFilterInput>;
  quantity?: InputMaybe<FloatOperationFilterInput>;
};

export type ProductTransactionMutations = {
  __typename?: 'ProductTransactionMutations';
  add: Scalars['UUID'];
  delete: Scalars['Boolean'];
  update: Scalars['Boolean'];
};


export type ProductTransactionMutationsAddArgs = {
  addRequest: AddProductTransactionRequestInput;
};


export type ProductTransactionMutationsDeleteArgs = {
  id: Scalars['UUID'];
};


export type ProductTransactionMutationsUpdateArgs = {
  updateRequest: UpdateProductTransactionRequestInput;
};

export type ProductTransactionQueries = {
  __typename?: 'ProductTransactionQueries';
  all: Array<ProductTransactionDto>;
  one: ProductTransactionDto;
};


export type ProductTransactionQueriesAllArgs = {
  order?: InputMaybe<Array<ProductTransactionDtoSortInput>>;
  where?: InputMaybe<ProductTransactionDtoFilterInput>;
};


export type ProductTransactionQueriesOneArgs = {
  id: Scalars['UUID'];
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StockQueries = {
  __typename?: 'StockQueries';
  materialStock: Array<MaterialStock>;
  productStock: Array<ProductStock>;
};


export type StockQueriesMaterialStockArgs = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};


export type StockQueriesProductStockArgs = {
  categories: Array<ProductCategory>;
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ncontains?: InputMaybe<Scalars['String']>;
  nendsWith?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nstartsWith?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type UpdateKnownNameRequestInput = {
  id: Scalars['UUID'];
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<KnownNameType>;
};

export type UpdateMaterialDefinitionRequestInput = {
  id: Scalars['UUID'];
  name?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
};

export type UpdateMaterialTransactionRequestInput = {
  id: Scalars['UUID'];
  materialDefinitionId?: InputMaybe<Scalars['UUID']>;
  quantity?: InputMaybe<Scalars['Float']>;
  receivedAt?: InputMaybe<Scalars['DateTime']>;
  receivedFromId?: InputMaybe<Scalars['UUID']>;
};

export type UpdateProductDefinitionRequestInput = {
  category: ProductCategory;
  id: Scalars['UUID'];
  name?: InputMaybe<Scalars['String']>;
  requirements?: InputMaybe<Array<UpdateProductDefinitionRequirementRequestInput>>;
  unit?: InputMaybe<Scalars['String']>;
};

export type UpdateProductDefinitionRequirementRequestInput = {
  materialDefinitionId?: InputMaybe<Scalars['UUID']>;
  productionLine: ProductProductionLine;
  quantity?: InputMaybe<Scalars['Float']>;
};

export type UpdateProductProductionRequestInput = {
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  productDefinitionId?: InputMaybe<Scalars['UUID']>;
  productionLine: ProductProductionLine;
  quantity?: InputMaybe<Scalars['Float']>;
  usages?: InputMaybe<Array<UpdateProductProductionUsageRequestInput>>;
  workerIds: Array<Scalars['UUID']>;
};

export type UpdateProductProductionUsageRequestInput = {
  materialDefinitionId?: InputMaybe<Scalars['UUID']>;
  quantity?: InputMaybe<Scalars['Float']>;
};

export type UpdateProductTransactionItemRequestInput = {
  price?: InputMaybe<Scalars['Float']>;
  productDefinitionId?: InputMaybe<Scalars['UUID']>;
  quantity?: InputMaybe<Scalars['Float']>;
};

export type UpdateProductTransactionRequestInput = {
  classification?: InputMaybe<ProductTransactionDetailsClassification>;
  comment?: InputMaybe<Scalars['String']>;
  deliveryState: DeliveryState;
  id: Scalars['UUID'];
  soldAt?: InputMaybe<Scalars['DateTime']>;
  soldToId?: InputMaybe<Scalars['UUID']>;
  transactions?: InputMaybe<Array<UpdateProductTransactionItemRequestInput>>;
};

export type UuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']>;
  gt?: InputMaybe<Scalars['UUID']>;
  gte?: InputMaybe<Scalars['UUID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  lt?: InputMaybe<Scalars['UUID']>;
  lte?: InputMaybe<Scalars['UUID']>;
  neq?: InputMaybe<Scalars['UUID']>;
  ngt?: InputMaybe<Scalars['UUID']>;
  ngte?: InputMaybe<Scalars['UUID']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  nlt?: InputMaybe<Scalars['UUID']>;
  nlte?: InputMaybe<Scalars['UUID']>;
};

export type WorkerDayResult = {
  __typename?: 'WorkerDayResult';
  day: Scalars['String'];
  dayOfWeek: Scalars['String'];
  products: Array<WorkerProductResult>;
  total: Scalars['String'];
};

export type WorkerProductResult = {
  __typename?: 'WorkerProductResult';
  amount: Scalars['String'];
  name: Scalars['String'];
};

export type WorkerProduction = {
  __typename?: 'WorkerProduction';
  productProduction?: Maybe<ProductProduction>;
  productProductionId: Scalars['UUID'];
  worker?: Maybe<KnownName>;
  workerId: Scalars['UUID'];
};

export type WorkerProductionFilterInput = {
  and?: InputMaybe<Array<WorkerProductionFilterInput>>;
  or?: InputMaybe<Array<WorkerProductionFilterInput>>;
  productProduction?: InputMaybe<ProductProductionFilterInput>;
  productProductionId?: InputMaybe<UuidOperationFilterInput>;
  worker?: InputMaybe<KnownNameFilterInput>;
  workerId?: InputMaybe<UuidOperationFilterInput>;
};

export type WorkerQueries = {
  __typename?: 'WorkerQueries';
  results: Array<WorkerResult>;
};


export type WorkerQueriesResultsArgs = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
  workerIds: Array<Scalars['UUID']>;
};

export type WorkerResult = {
  __typename?: 'WorkerResult';
  days: Array<WorkerDayResult>;
  name: Scalars['String'];
};
